import { styled } from "@mui/material/styles";
import _Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";

const Wrapper = styled(_Card)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(1),
  minWidth: 500,
  maxWidth: 700,
  [theme.breakpoints.down("md")]: {
    margin: "auto",
  },
  [theme.breakpoints.only("sm")]: {
    minWidth: 400,
  },
}));

export const Card = ({ title, children }) => {
  return (
    <Wrapper>
      <CardHeader title={title} sx={{ backgroundColor: "primary.accent" }} />
      <CardContent>{children}</CardContent>
    </Wrapper>
  );
};
