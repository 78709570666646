import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import styled from "@emotion/styled";

import { drawerWidth } from ".";

export const Wrapper = styled(Toolbar)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minHeight: 0
}));

export const Footer = ({ children }) => {
  return (
    <AppBar
      component="footer"
      position="fixed"
      color="primary"
      sx={{
        top: "auto",
        bottom: 0,
        right: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        ml: `${drawerWidth}px`,
        "& button": {
          mx: "1rem",
        },
      }}
    >
      <Wrapper>{children}</Wrapper>
    </AppBar>
  );
};
