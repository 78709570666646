import { useState, useContext, useMemo } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useFuzzy } from "react-use-fuzzy";
import { useLocation } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";

import { Wrapper } from ".";
import { Footer, Button, Dialog, Toolbar, Toast, PinBox } from "../components";
import {
  // AddMachineStoppage,
  EditMachineStoppage,
  ConfirmStoppage,
} from "../components/forms";
import {
  AbilityContext,
  usePlant,
  useShifts,
  useFetchMachineStoppagesQuery,
  useUpdateMachineStoppageMutation,
  useStoppages,
  useLanguageSuffix,
  useValidateUserActionMutation,
} from "../state";
import { formatDate, formatStartTime } from "../util";

export default () => {
  const { t } = useTranslation(["translation", "actions"]);
  const ability = useContext(AbilityContext);
  const userStation = useSelector((state) => state.user.station);
  const { state } = useLocation();
  const { allLines: LINES, machinesByLine } = usePlant();
  const [line, setLine] = useState(userStation?.line || "");
  const [machine, setMachine] = useState(userStation?.machine || "");
  const [pin, setPin] = useState("");
  const [isVerified, setIsValidated] = useState(false);

  const { suffixize, desuffixize } = useLanguageSuffix();
  const { currentShift } = useShifts({ line });
  const {
    stoppageTypes: TYPES,
    stoppageGroups: GROUPS,
    stoppageSubgroups: SUBGROUPS,
  } = useStoppages();

  const MACHINES = useMemo(
    () => machinesByLine?.[line]?.map?.(({ machine }) => machine),
    [line, machinesByLine]
  );
  const {
    data,
    error: fetchMachineStoppagesError,
    isLoading,
    refetch,
  } = useFetchMachineStoppagesQuery(
    {
      line,
      machine,
      startTime: moment(currentShift?.startTime, "HH:mm")?.toISOString(),
      endTime: moment(currentShift?.endTime, "HH:mm")?.toISOString(),
    },
    {
      skip: !Boolean(line) || !Boolean(machine),
    }
  );

  const [onUpdateMachineStoppage, { isLoading: isUpdating }] =
    useUpdateMachineStoppageMutation();
  const [onValidateUserAction, { isLoading: isValidating }] =
    useValidateUserActionMutation();
  const [selected, setSelected] = useState(null);
  // const [isAddOpen, setIsAddOpen] = useState(Boolean(state?.isAddOpen));
  const [isEditOpen, setIsEditOpen] = useState(Boolean(state?.isEditOpen));
  const [isConfirmOpen, setIsConfirmOpen] = useState(
    Boolean(state?.isConfirmOpen)
  );
  const [hasError, setHasError] = useState(
    Boolean(fetchMachineStoppagesError) &&
      Object.keys(fetchMachineStoppagesError).length
  );

  const {
    result: rows = [],
    keyword,
    search,
  } = useFuzzy(data, {
    keys: ["enrichedReason"],
    isCaseSensitive: true,
    minMatchCharLength: 2,
    shouldSort: true,
    findAllMatches: true,
  });
  const columns = [
    { field: "id", hide: true },
    {
      field: "startTime",
      renderCell: ({ row }) => formatStartTime(moment(row.startTime).local()),
      flex: 0.5
    },
    { field: "Duration", keyName: "duration", flex: false },
    { field: "Type", keyName: "stoppageType", flex: 0.5 },
    {
      field: "Reason",
      renderCell: ({ row }) => {
        const { enrichedReason, originalReason } = row;

        let reason = "";

        if (originalReason && !enrichedReason) {
          reason = originalReason
            .map((elem) => {
              const { reasonLabel } = desuffixize(elem, ["reasonLabel"]);
              return reasonLabel;
            })
            .join(",");
        } else if (originalReason && enrichedReason) {
          const enrichedReasonEntry = originalReason.find((elem) => {
            return elem.stoppageCode === enrichedReason;
          });

          const { reasonLabel } = desuffixize(enrichedReasonEntry, [
            "reasonLabel",
          ]);
          reason = reasonLabel;
        }

        return reason;
      },
    },
    {
      field: "Review",
      flex: false,
      renderCell: ({ api, row }) => {
        return !Boolean(row?.needsOperatorConfirmation) ? (
          <CheckIcon fontSize="small" stroke="green" />
        ) : (
          <CloseIcon fontSize="small" stroke="red" />
        );
      },
    },
  ].map(({ field, keyName, ...rest }) => ({
    field: keyName || field,
    headerName: t(field),
    flex: true,
    align: "center",
    headerAlign: "center",
    ...rest,
  }));

  const onRowClick = (params, event) => {
    const { row } = params;
    const { target } = event;

    if (row.id !== selected?.id) {
      setSelected(row);
      setTimeout(() => {
        target.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }, 30);
    }
  };

  // const onAddStoppage = (data) => {
  //   const {
  //     groupType,
  //     stoppageType,
  //     groupCode,
  //     startTime,
  //     explanation: stoppageName,
  //     ...rest
  //   } = data;
  //   const toSend = suffixize({
  //     groupType,
  //     stoppageType,
  //     groupCode,
  //     stoppageName,
  //   });

  //   console.log({
  //     addMachineStoppage: {
  //       ...toSend,
  //       startTime: formatDate(moment(startTime)),
  //       ...rest,
  //     },
  //   });
  //   // setIsAddOpen(false);
  // };

  const onVerify = async () => {
    try {
      const result = await onValidateUserAction({
        code: pin,
      }).unwrap();

      setIsValidated(true);
    } catch (validateError) {
      setIsValidated(false);
      setHasError(validateError?.data?.message);
    } finally {
      setPin("");
    }
  };

  const onEditStoppage = async (data) => {
    try {
      const { stoppageCodeId } = data;
      const { id } = selected;
      const result = await onUpdateMachineStoppage({
        id,
        stoppageCodeId,
      }).unwrap();

      refetch();
    } catch (editStoppageError) {
      setHasError(editStoppageError?.data?.message);
    } finally {
      setSelected(null);
      setIsEditOpen(false);
      setIsValidated(false);
    }
  };

  const onConfirmStoppage = async (data) => {
    try {
      const { stoppageCodeId } = data;
      const { id } = selected;
      const result = await onUpdateMachineStoppage({
        id,
        stoppageCodeId,
      }).unwrap();

      refetch();
    } catch (confirmStoppageError) {
      setHasError(confirmStoppageError?.data?.message);
    } finally {
      setSelected(null);
      setIsConfirmOpen(false);
      setIsValidated(false);
    }
  };

  const onCancelEdit = () => {
    setIsEditOpen(false);
    setIsValidated(false);
  };

  const onCancelConfirm = () => {
    setIsConfirmOpen(false);
    setIsValidated(false);
  };

  return (
    <Wrapper component="section" flex>
      <div style={{ flexGrow: 1 }}>
        <DataGrid
          rows={
            Boolean(fetchMachineStoppagesError) || isLoading
              ? []
              : rows?.[0]?.item
              ? rows.map(({ item }) => item)
              : rows
          }
          columns={columns}
          autoHeight
          pageSize={25}
          components={{ Toolbar }}
          componentsProps={{
            toolbar: {
              search,
              keyword,
              LINES,
              MACHINES,
              ...(ability.can("edit", "Operator")
                ? { line, setLine, machine, setMachine }
                : {}),
            },
          }}
          sx={{
            "& .MuiDataGrid-cell:focus": {
              outline: "none",
            },
            "& .MuiDataGrid-columnHeader:focus": {
              outline: "none",
            },
          }}
          onRowClick={onRowClick}
        />
      </div>
      <Footer>
        {/* <Button
          disabled={!Boolean(line) || !Boolean(machine)}
          onClick={() => setIsAddOpen(true)}
        >
          {t("Add Stoppage", { ns: "actions" })}
        </Button> */}
        <Button
          onClick={() => setIsEditOpen(true)}
          disabled={
            !selected?.needsOperatorConfirmation ||
            Boolean(selected?.originalReason.length)
          }
        >
          {t("Edit Stoppage", { ns: "actions" })}
        </Button>
        <Button
          onClick={() => setIsConfirmOpen(true)}
          disabled={
            !selected?.needsOperatorConfirmation ||
            !Boolean(selected?.originalReason.length)
          }
        >
          {t("Confirm Stoppage", { ns: "actions" })}
        </Button>
      </Footer>
      {/* <Dialog
        isOpen={isAddOpen}
        handleClose={() => setIsAddOpen(false)}
        title={t("Add Stoppage", { ns: "actions" })}
      >
        <AddMachineStoppage
          onSubmit={onAddStoppage}
          onCancel={() => setIsAddOpen(false)}
          TYPES={TYPES}
          GROUPS={GROUPS}
          SUBGROUPS={SUBGROUPS}
          line={line}
          machine={machine}
        />
      </Dialog> */}
      <Dialog
        isOpen={isEditOpen}
        handleClose={onCancelEdit}
        title={t("Edit Stoppage", { ns: "actions" })}
      >
        {ability.can("edit", "Operator") || isVerified ? (
          <EditMachineStoppage
            onSubmit={onEditStoppage}
            onCancel={onCancelEdit}
            TYPES={TYPES}
            GROUPS={GROUPS}
            SUBGROUPS={SUBGROUPS}
            line={line}
            machine={machine}
          />
        ) : (
          <Box my={2}>
            <PinBox setPin={setPin} pin={pin} onSave={onVerify} invertColors />
          </Box>
        )}
      </Dialog>
      <Dialog
        isOpen={isConfirmOpen}
        handleClose={onCancelConfirm}
        title={t("Confirm Stoppage", { ns: "actions" })}
      >
        {ability.can("edit", "Operator") || isVerified ? (
          <ConfirmStoppage
            onSubmit={onConfirmStoppage}
            onCancel={onCancelConfirm}
            selected={selected}
          />
        ) : (
          <Box my={2}>
            <PinBox setPin={setPin} pin={pin} onSave={onVerify} invertColors />
          </Box>
        )}
      </Dialog>
      <Toast
        isOpen={Boolean(hasError)}
        onClose={() => setHasError(false)}
        severity="error"
      >
        {t(fetchMachineStoppagesError?.data?.message || hasError)}
      </Toast>
    </Wrapper>
  );
};
