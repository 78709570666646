import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ButtonGroup from "@mui/material/ButtonGroup";
import { useMemo } from "react";

import { FormWrapper } from ".";
import { Select, Button } from "..";
import { useStoppages } from "../../state";

export default ({
  TYPES = [],
  GROUPS = [],
  SUBGROUPS = [],
  onSubmit = () => {},
  onCancel = () => {},
  line,
  machine,
}) => {
  const { t } = useTranslation();
  const { control, handleSubmit, watch, reset } = useForm({
    mode: "onSubmit",
    defaultValues: {
      groupType: "",
      stoppageType: "",
      groupCode: "",
      reason: "",
    },
  });
  const watched = watch();
  const { stoppageCodes = [] } = useStoppages(
    {
      line,
      machine,
      stoppageType: watched.stoppageType,
      groupType: watched.groupType,
      groupCode: watched.groupCode,
    },
    {
      skip:
        !Boolean(line) ||
        !Boolean(machine) ||
        !Boolean(watched.stoppageType) ||
        !Boolean(watched.groupType) ||
        !Boolean(watched.groupCode),
    }
  );

  const REASONS = useMemo(() => {
    return stoppageCodes.map(({ stoppageName, id }) => ({
      value: stoppageName,
      id,
    }));
  }, [stoppageCodes]);

  const handleFormSubmit = (data) => {
    const { reason } = data;

    const found = REASONS.find(({ value }) => value === reason);

    if (found) {
      onSubmit({ stoppageCodeId: found?.id });
    }

    handleReset();
  };

  const handleReset = () => reset();

  return (
    <FormWrapper onSubmit={handleSubmit(handleFormSubmit)}>
      <Box>
        <Typography color="text.secondary" component="span" variant="h6">
          {t("Type")}
        </Typography>
        <Controller
          name="groupType"
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState }) => (
            <Select
              sx={{ minWidth: "5rem" }}
              options={TYPES}
              variant="standard"
              error={!field.value?.length}
              {...field}
            />
          )}
        />
      </Box>
      <Box>
        <Typography color="text.secondary" component="span" variant="h6">
          {t("Group")}
        </Typography>
        <Controller
          name="stoppageType"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Select
              sx={{ minWidth: "5rem" }}
              options={GROUPS?.[watched.groupType]}
              variant="standard"
              disabled={!Boolean(watched.groupType)}
              {...field}
            />
          )}
        />
      </Box>
      <Box>
        <Typography color="text.secondary" component="span" variant="h6">
          {t("Subgroup")}
        </Typography>
        <Controller
          name="groupCode"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Select
              sx={{ minWidth: "5rem" }}
              options={SUBGROUPS?.[watched.groupType]?.[watched.stoppageType]}
              variant="standard"
              disabled={!Boolean(watched.stoppageType)}
              {...field}
            />
          )}
        />
      </Box>
      <Box>
        <Typography color="text.secondary" component="span" variant="h6">
          {t("Reason")}
        </Typography>
        <Controller
          name="reason"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Select
              sx={{ minWidth: "5rem" }}
              options={REASONS}
              variant="standard"
              disabled={!Boolean(watched.groupCode)}
              {...field}
            />
          )}
        />
      </Box>
      <ButtonGroup
        variant="outlined"
        component="span"
        size="large"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
        }}
      >
        <Button onClick={onCancel}>{t("Cancel")}</Button>
        <Button onClick={handleReset}>{t("Reset")}</Button>
        <Button type="submit" color="success" variant="contained">
          {t("Edit")}
        </Button>
      </ButtonGroup>
    </FormWrapper>
  );
};
