import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default ({ entity = {}, heading = "" }) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ width: "100%" }}>
      <Typography color="text.secondary" component="div" variant="h6" my={2}>
        {heading}
      </Typography>
      {entity &&
        Object.entries(entity).map(([key, value]) => {
          return (
            <Box
              key={key}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography color="text.secondary" component="span" variant="h6">
                {t(key)}
              </Typography>
              <Typography
                color="text.secondary"
                component="span"
                variant="body1"
                ml="auto"
              >
                {value}
              </Typography>
              <br />
            </Box>
          );
        })}
    </Box>
  );
};
