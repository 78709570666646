import { useState, useRef, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useFuzzy } from "react-use-fuzzy";
import { useSelector } from "react-redux";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

import { Wrapper } from ".";
import { Toolbar, Toast } from "../components/";
import { useFetchUsersQuery, useUpdateUserMutation } from "../state/";
import { EditUser } from "../components/forms";

export default () => {
  const { t } = useTranslation();
  const USER_ROLES = useSelector((state) => state.user.USER_ROLES);
  const {
    data,
    error: fetchUsersError,
    isLoading,
    refetch,
  } = useFetchUsersQuery();
  const [onUpdateUser, { isLoading: isUpdating }] = useUpdateUserMutation();
  const [selected, setSelected] = useState(null);
  const [hasError, setHasError] = useState(
    Boolean(fetchUsersError) && Object.keys(fetchUsersError).length
  );
  const [hasPinError, setHasPinError] = useState(false);
  const fieldRef = useRef(null);

  const {
    result: rows = [],
    keyword,
    search,
  } = useFuzzy(data, {
    keys: ["name", "surname", "email"],
    isCaseSensitive: true,
    minMatchCharLength: 2,
    shouldSort: true,
    findAllMatches: true,
  });
  const columns = [
    { field: "id", hide: true },
    { field: "name", flex: 0.5 },
    { field: "surname", flex: 0.3 },
    { field: "email", flex: 0.6 },
    { field: "role", flex: 0.3 },
    { field: "username", flex: 0.2 },
    {
      field: "status",
      flex: false,
      renderCell: ({ api, row }) => {
        return Boolean(row?.status) ? (
          <CheckIcon fontSize="small" stroke="green" />
        ) : (
          <CloseIcon fontSize="small" stroke="red" />
        );
      },
    },
  ].map(({ field, ...rest }) => ({
    field,
    headerName: t(field),
    flex: true,
    align: "center",
    headerAlign: "center",
    ...rest,
  }));

  useEffect(() => {
    if (fieldRef.current) {
      fieldRef.current.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, [selected]);

  const onRowClick = (params, event) => {
    const { row } = params;

    if (row.id !== selected?.id) {
      setSelected(row);
    }
  };

  const onEditUser = async (data) => {
    const {
      pinCode,
      confirmPinCode,
      role,
      email,
      name,
      surname,
      status,
      username,
    } = data;
    const { id } = selected;

    if (pinCode === confirmPinCode) {
      try {
        const result = await onUpdateUser({
          pinCode,
          role,
          email,
          name,
          surname,
          id,
          status,
          username,
        }).unwrap();

        refetch();
      } catch (editUserError) {
        setHasError(editUserError?.data?.message);
      } finally {
        setSelected(null);
      }
    } else {
      setHasPinError(true);
    }
  };

  return (
    <Wrapper component="section" flex>
      <div style={{ flexGrow: 1 }}>
        <DataGrid
          rows={
            Boolean(fetchUsersError) || isLoading
              ? []
              : rows?.[0]?.item
              ? rows.map(({ item }) => item)
              : rows
          }
          getRowId={(row) => row._id}
          columns={columns}
          autoHeight
          pageSize={5}
          components={{ Toolbar }}
          componentsProps={{
            toolbar: { search, keyword },
          }}
          sx={{
            "& .MuiDataGrid-cell:focus": {
              outline: "none",
            },
            "& .MuiDataGrid-columnHeader:focus": {
              outline: "none",
            },
          }}
          onRowClick={onRowClick}
        />
      </div>
      {Boolean(selected) && (
        <EditUser
          ref={fieldRef}
          user={selected}
          roles={USER_ROLES}
          onSubmit={onEditUser}
          onCancel={() => setSelected(null)}
        />
      )}
      <Toast
        isOpen={hasError}
        onClose={() => setHasError(false)}
        severity="error"
      >
        {t(fetchUsersError?.data?.message || hasError)}
      </Toast>
      <Toast
        isOpen={hasPinError}
        onClose={() => setHasPinError(false)}
        severity="warning"
      >
        {t("PIN codes do not match")}
      </Toast>
    </Wrapper>
  );
};
