import { useEffect, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ButtonGroup from "@mui/material/ButtonGroup";
import styled from "@emotion/styled";
import Checkbox from "@mui/material/Checkbox";

import { TextInput, NumberInput, Button, Select } from "..";

const FormWrapper = styled.form(({ theme }) => ({
  padding: "0 4rem",
  [theme.breakpoints.down("md")]: {
    padding: "0 1rem",
  },
  "& > div": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0.5rem 0",
    padding: "0.5rem",

    "& > *": {
      margin: "1rem",
    },
  },
  "& > div > div": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    "& > .MuiTypography-root": {
      color: theme.palette.secondary,
    },
  },
}));

export default forwardRef(
  ({ user, roles, onSubmit = () => {}, onCancel = () => {} }, ref) => {
    const { t } = useTranslation();
    const { control, handleSubmit, reset } = useForm({
      mode: "onSubmit",
      defaultValues: user || {
        name: "",
        surname: "",
        email: "",
        role: "",
        username: "",
        pinCode: "",
        confirmPinCode: "",
        status: false,
      },
    });

    useEffect(() => {
      reset(user);
    }, [user]);

    const handleFormSubmit = (data, event) => {
      event.preventDefault();
      onSubmit(data);
    };

    return (
      <FormWrapper ref={ref} onSubmit={handleSubmit(handleFormSubmit)}>
        <Box>
          <Box>
            <Typography color="text.secondary" component="span" variant="h6">
              {t("name")}
            </Typography>
            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextInput inputProps={{ readOnly: true }} {...field} />
              )}
            />
          </Box>
          <Box>
            <Typography color="text.secondary" component="span" variant="h6">
              {t("surname")}
            </Typography>
            <Controller
              name="surname"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextInput inputProps={{ readOnly: true }} {...field} />
              )}
            />
          </Box>
        </Box>
        <Box>
          <Box>
            <Typography color="text.secondary" component="span" variant="h6">
              {t("email")}
            </Typography>
            <Controller
              name="email"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextInput inputProps={{ readOnly: true }} {...field} />
              )}
            />
          </Box>
          <Box>
            <Typography color="text.secondary" component="span" variant="h6">
              {t("username")}
            </Typography>
            <Controller
              name="username"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextInput inputProps={{ readOnly: true }} {...field} />
              )}
            />
          </Box>
          <Box>
            <Typography color="text.secondary" component="span" variant="h6">
              {t("role")}
            </Typography>
            <Controller
              name="role"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  inputProps={{ readOnly: true }}
                  options={roles}
                  variant="standard"
                  {...field}
                />
              )}
            />
          </Box>
        </Box>
        <Box>
          <Box>
            <Typography color="text.secondary" component="span" variant="h6">
              {t("PIN")}
            </Typography>
            <Controller
              name="pinCode"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <NumberInput
                  size="medium"
                  inputProps={{
                    minLength: 4,
                    maxLength: 4,
                    inputMode: "decimal",
                    pattern: "^[0-9]{4}$",
                  }}
                  error={!field.value?.length}
                  {...field}
                />
              )}
            />
          </Box>
          <Box>
            <Typography color="text.secondary" component="span" variant="h6">
              {t("Confirm PIN")}
            </Typography>
            <Controller
              name="confirmPinCode"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <NumberInput
                  size="medium"
                  inputProps={{
                    minLength: 4,
                    maxLength: 4,
                    inputMode: "decimal",
                    pattern: "^[0-9]{4}$",
                  }}
                  error={!field.value?.length}
                  {...field}
                />
              )}
            />
          </Box>
          <Box>
            <Typography color="text.secondary" component="span" variant="h6">
              {t("Active")}
            </Typography>
            <Controller
              name="status"
              control={control}
              render={({ field }) => {
                return (
                  <Checkbox
                    checked={field.value}
                    inputProps={{ readOnly: true }}
                    {...field}
                  />
                );
              }}
            />
          </Box>
        </Box>
        <ButtonGroup
          variant="outlined"
          component="span"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <Button onClick={onCancel}>{t("Cancel")}</Button>
          <Button type="submit" color="success" variant="contained">
            {t("Edit")}
          </Button>
        </ButtonGroup>
      </FormWrapper>
    );
  }
);
