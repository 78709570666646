import Box from "@mui/material/Box";
import { GridToolbar } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import throttle from "lodash.throttle";

import { Select, TextInput } from ".";

export const Toolbar = ({
  search,
  keyword,
  line = null,
  setLine,
  machine = null,
  setMachine,
  LINES,
  MACHINES,
  ...rest
}) => {
  const { t } = useTranslation();
  const throttledSearch = throttle(search, 30);

  return (
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
      {...rest}
    >
      <GridToolbar />
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          gap: ".5rem",
          marginTop: ".7rem",
        }}
      >
        {line !== null && (
          <Select
            label={t("Line")}
            value={line}
            options={LINES}
            onSelect={setLine}
            sx={{ minWidth: "5rem" }}
            variant="standard"
          />
        )}
        {machine !== null && (
          <Select
            label={t("Machine")}
            value={machine}
            options={MACHINES}
            onSelect={setMachine}
            sx={{ minWidth: "7rem" }}
            variant="standard"
          />
        )}
        <TextInput
          label={t("Search")}
          value={keyword}
          onChange={e => throttledSearch(e.target.value)}
          inputRef={input => keyword?.length && input && input.focus()}
        />
      </Box>
    </Box>
  );
};
