import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { OneOffQrScanner } from "react-webcam-qr-scanner.ts";
import { styled } from "@mui/material/styles";
import ButtonGroup from "@mui/material/ButtonGroup";
import { useSelector, useDispatch } from "react-redux";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Footer, Button, Dialog, Toast } from "../components/";
import { Wrapper } from ".";
import {
  setUserStation,
  useCheckinUserMutation,
  useCheckoutUserMutation,
  useFetchCheckinStatusQuery,
} from "../state/";

const Heading = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    fontSize: "2rem",
    margin: 0,
    padding: 0,
  },
}));

const Scanner = styled(OneOffQrScanner)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    height: 250,
    width: 250,
    margin: 0,
    padding: 0,
  },
  [theme.breakpoints.up("md")]: {
    height: 512,
    width: 512,
  },
}));

export default () => {
  const { t } = useTranslation(["translation", "actions"]);
  const dispatch = useDispatch();
  const [QrCode, setQrCode] = useState(null);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [hasError, setHasError] = useState(false);
  const userStation = useSelector((state) => state.user.station);
  const userToken = useSelector((state) => state.user.token);
  const [onCheckinUser] = useCheckinUserMutation();
  const [onCheckoutUser] = useCheckoutUserMutation();
  const isCheckedIn = Boolean(userStation?.plantName);
  const { data: checkinStatus, error: fetchCheckinStatusError } =
    useFetchCheckinStatusQuery({
      skip: !Boolean(userToken),
    });

  const onScan = (decoded) => {
    if (decoded) {
      try {
        const parsed = JSON.parse(decoded);

        if (parsed.plantName) {
          setQrCode(parsed);
        }
      } catch {}
    }
  };

  useEffect(() => {
    if (userToken) {
      dispatch(setUserStation(checkinStatus));
    }
  }, [userToken]);

  const onRescan = () => {
    setQrCode(null);
  };

  const onCheckin = async () => {
    try {
      const result = await onCheckinUser(QrCode).unwrap();

      dispatch(setUserStation(QrCode));
      setQrCode(null);
    } catch (checkinError) {
      setHasError(checkinError?.data?.message);
    } finally {
      setIsConfirmOpen(false);
    }
  };

  const onCheckout = async () => {
    try {
      const result = await onCheckoutUser(QrCode).unwrap();

      dispatch(setUserStation(null));
      setQrCode(null);
    } catch (checkoutError) {
      setHasError(checkoutError?.data?.message);
    } finally {
      setIsConfirmOpen(false);
    }
  };

  return (
    <Wrapper component="section" flex center>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        {userStation || QrCode ? (
          <>
            <Heading variant="h4" gutterBottom>
              {t("Your current station")}
            </Heading>
            <List>
              {Object.entries(userStation || QrCode).map(([key, value]) => {
                return (
                  <ListItem key={key} divider>
                    <ListItemText variant="body" inset sx={{ paddingLeft: 0 }}>
                      <b>{t(key)}: </b>
                      {value}
                    </ListItemText>
                  </ListItem>
                );
              })}
            </List>
          </>
        ) : (
          <>
            <Heading variant="h6">{t("Scan station's QR code")}</Heading>
            <Scanner onQrCode={onScan} />
            <Heading variant="body">{t("No Station Selected")}</Heading>
          </>
        )}
      </Box>
      <Footer>
        <Button onClick={onRescan} disabled={isCheckedIn || !Boolean(QrCode)}>
          {t("Rescan", { ns: "actions" })}
        </Button>
        <Button
          onClick={() => setIsConfirmOpen(true)}
          disabled={isCheckedIn || !Boolean(QrCode)}
        >
          {t("Check In", { ns: "actions" })}
        </Button>
        <Button onClick={() => setIsConfirmOpen(true)} disabled={!isCheckedIn}>
          {t("Check Out", { ns: "actions" })}
        </Button>
      </Footer>
      <Dialog
        isOpen={isConfirmOpen}
        handleClose={() => setIsConfirmOpen(false)}
        title={t("Are you sure?")}
        actions={
          <ButtonGroup
            variant="outlined"
            component="span"
            size="large"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Button onClick={() => setIsConfirmOpen(false)}>{t("No")}</Button>
            <Button
              onClick={isCheckedIn ? onCheckout : onCheckin}
              color="success"
              variant="contained"
            >
              {t("Yes")}
            </Button>
          </ButtonGroup>
        }
      >
        <pre>
          <Heading variant="h6" gutterBottom>
            {t(`Check ${isCheckedIn ? "Out" : "In"}`, { ns: "actions" })}
          </Heading>
          <List>
            {(userStation || QrCode) &&
              Object.entries(userStation || QrCode).map(([key, value]) => {
                return (
                  <ListItem key={key} divider sx={{ paddingLeft: 0 }}>
                    <ListItemText variant="body" inset>
                      <b>{t(key)}: </b>
                      {value}
                    </ListItemText>
                  </ListItem>
                );
              })}
          </List>
        </pre>
      </Dialog>
      <Toast
        isOpen={hasError}
        onClose={() => setHasError(false)}
        severity="error"
      >
        {t(hasError)}
      </Toast>
    </Wrapper>
  );
};
