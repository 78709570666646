import { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Box from "@mui/material/Box";
import ButtonGroup from "@mui/material/ButtonGroup";
import { useNavigate } from "react-router-dom";

import { Wrapper } from ".";
import { Table, Toast, WeekPickerInput, Select, Button } from "../components/";
import {
  useFetchShiftPlanningCodesQuery,
  useCreateShiftPlansMutation,
  useFetchShiftPlansQuery,
  usePlant,
} from "../state/";

export default () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { allLines: LINES, currentPlant } = usePlant();
  const [line, setLine] = useState("");
  const [selectedDate, setSelectedDate] = useState(moment().add(7, "d"));
  const {
    data: shiftPlans,
    error: fetchShiftPlansError,
    refetch,
  } = useFetchShiftPlansQuery(
    {
      lineId: line,
      plant: currentPlant,
      startDate: moment(selectedDate).startOf("week")?.toISOString(),
      endDate: moment(selectedDate).endOf("week")?.toISOString(),
    },
    {
      skip: !Boolean(line),
    }
  );
  const { data: shiftPlanningCodes, error: fetchShiftPlanningCodesError } =
    useFetchShiftPlanningCodesQuery();
  const [onCreateShiftPlans, { isLoading: isCreating }] =
    useCreateShiftPlansMutation();
  const [shiftData, setShiftData] = useState([]);
  const [hasError, setHasError] = useState(
    Boolean(fetchShiftPlansError) && Object.keys(fetchShiftPlansError).length
  );

  const STOPPAGE_CODES = useMemo(() => {
    return shiftPlanningCodes?.map?.(({ ShiftPlanningStatus, viewColor }) => ({
      value: ShiftPlanningStatus,
      viewColor,
    }));
  }, [shiftPlanningCodes]);

  const ROWS = useMemo(() => {
    if (!shiftPlans) return [];

    return Object.entries(shiftPlans).map?.(([shiftName, days]) => {
      return [shiftName].concat(days);
    });
  }, [shiftPlans]);

  const numberOfEntriesToEdit = useMemo(() => {
    if (!shiftPlans) return Infinity;

    const number = Object.entries(shiftPlans)?.reduce?.(
      (acc, [shiftName, days]) => {
        const editableEntries = days.filter(({ isEditable }) => isEditable);

        return acc + editableEntries.length;
      },
      0
    );

    return number ? number : Infinity;
  }, [shiftPlans]);

  const onPickChange = (date) => {
    setSelectedDate(date);
  };

  const onSavePlans = async () => {
    const allEntries = Object.entries(shiftPlans)
      .map(([_, plans]) => plans)
      .flat()
      .map(({ date, shiftStatus, shiftId, lineId, isEditable }) => ({
        shiftPlanDate: date,
        shiftStatus,
        shiftId,
        lineId,
        isEditable,
      }));

    const toSend = allEntries
      .map((entry) => {
        const { isEditable, ...rest } = entry;
        if (!isEditable) return rest;

        const editedEntry = shiftData.find(({ shiftId, shiftPlanDate }) => {
          return (
            shiftId === entry.shiftId && shiftPlanDate === entry.shiftPlanDate
          );
        });

        return editedEntry ?? entry;
      })
      .map((elem) => ({
        ...elem,
        plant: currentPlant,
      }));

    try {
      const result = await onCreateShiftPlans({ shiftPlans: toSend }).unwrap();

      refetch();
    } catch (savePlansError) {
      setHasError(
        `${savePlansError?.data?.message} - ${savePlansError?.data?.error}`
      );
    }
  };

  return (
    <Wrapper component="section" flex>
      <Box sx={{ display: "flex", marginBottom: "1rem" }}>
        <Select
          label={t("Line")}
          value={line}
          options={LINES}
          onSelect={setLine}
          sx={{ minWidth: "5rem", marginRight: "auto" }}
          variant="standard"
        />
        <WeekPickerInput
          value={selectedDate}
          onChange={onPickChange}
          disabled={!Boolean(line)}
        />
      </Box>
      <Table
        title={t("shiftName")}
        columns={makeColumns(selectedDate)}
        rows={ROWS}
        STOPPAGE_CODES={STOPPAGE_CODES}
        padding="none"
        size="small"
        onChange={setShiftData}
        value={shiftData}
      />
      <ButtonGroup
        variant="outlined"
        component="span"
        size="large"
        sx={{
          marginTop: ".5rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
        }}
      >
        <Button onClick={() => navigate("/production-status")}>
          {t("Cancel")}
        </Button>
        <Button
          disabled={false}
          color="success"
          variant="contained"
          onClick={onSavePlans}
        >
          {t("Save")}
        </Button>
      </ButtonGroup>
      <Toast
        isOpen={Boolean(hasError)}
        onClose={() => setHasError(false)}
        severity="error"
      >
        {t(fetchShiftPlansError?.data?.message || hasError)}
      </Toast>
    </Wrapper>
  );
};

function makeColumns(date) {
  const startOfWeek = moment(date).startOf("week");

  return Array.from({ length: 7 }, (_, idx) => {
    return moment(startOfWeek).add(idx, "d").format("DD.MM.YYYY");
  });
}
