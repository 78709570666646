import * as React from "react";
import _Snackbar from "@mui/material/Snackbar";
import _Alert from "@mui/material/Alert";
import ms from "ms";

export const Alert = React.forwardRef((props, ref) => {
  const { elevation = 6, variant = "filled", severity = "info" } = props;

  return (
    <_Alert
      elevation={elevation}
      ref={ref}
      variant={variant}
      severity={severity}
      {...props}
    />
  );
});

export const Toast = props => {
  const {
    isOpen = false,
    autoHideDuration = ms("6s"),
    onClose = () => {},
    severity = "info",
    anchorOrigin = { vertical: "bottom", horizontal: "right" },
    children,
    ...rest
  } = props;

  return (
    <_Snackbar
      open={isOpen}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
      {...rest}
    >
      <Alert onClose={onClose} severity={severity} sx={{ width: "100%" }}>
        {children}
      </Alert>
    </_Snackbar>
  );
};
