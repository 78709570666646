import { LogLevel } from '@azure/msal-browser';

// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
export const msalConfig = {
	auth: {
		clientId: 'e82f8114-aca6-4ee5-a5d7-c649912c8e04',
		authority:
			'https://login.microsoftonline.com/e4dddef5-d743-42fa-99da-83120e7bf32e',
		knownAuthorities: ['https://login.microsoftonline.com'],
		redirectUri: 'https://api.twinmaster.io/login/callback',
		postLogoutRedirectUri: 'https://api.twinmaster.io/logout/callback',
	},
	cache: {
		cacheLocation: 'sessionStorage',
	},
	system: {
		loggerOptions: {
			loggerCallback: (level, message, containsPii) => {
				if (process.env.NODE_ENV === 'production') return;
				if (containsPii) return;

				switch (level) {
					case LogLevel.Error:
						console.error(message);
						return;
					case LogLevel.Info:
						console.info(message);
						return;
					case LogLevel.Verbose:
						console.debug(message);
						return;
					case LogLevel.Warning:
						console.warn(message);
						return;
					default:
						console.log(message);
						return;
				}
			},
		},
	},
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
	scopes: ['User.Read'],
};

/**
 * Add here the scopes to request when obtaining an access token for MS Graph API. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const graphConfig = {
	graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};
