import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const API = createApi({
	reducerPath: 'api',
	baseQuery: fetchBaseQuery({
		baseUrl: 'https://api.twinmaster.io/',
		prepareHeaders: (headers, { getState }) => {
			const state = getState();
			headers.set('Authorization', 'DRINK_COKE_BYPASS_AUTH');

			return headers;
		},
	}),
	refetchOnMountOrArgChange: true,
	// refetchOnFocus: true,
	// refetchOnReconnect: true,
	endpoints: (builder) => ({
		fetchProductionStatus: builder.query({
			query: (data) => {
				return {
					url: `operation/production/status`,
					params: data,
				};
			},
		}),
		fetchProductionStatusProducts: builder.query({
			query: (data) => {
				return {
					url: `operation/production/products`,
					params: data,
				};
			},
		}),
		fetchLines: builder.query({
			query: () => `operation/line/machine`,
			transformResponse: (data) => {
				return data.map((elem) => ({ id: elem?._id, ...elem }));
			},
		}),
		fetchShiftDefinitions: builder.query({
			query: () => `operation/shift/definition`,
			transformResponse: (data) => {
				return data.map((elem) => ({ id: elem?._id, ...elem }));
			},
		}),
		fetchActiveShift: builder.query({
			query(data) {
				return {
					url: `operation/shift/definition/active`,
					params: data,
				};
			},
			transformResponse: (data) => {
				const newData = { ...data };
				newData.id = data._id;

				return data;
			},
		}),
		createShiftDefinition: builder.mutation({
			query({ shiftName, startTime, endTime, plant }) {
				return {
					url: `operation/shift/definition`,
					method: 'POST',
					body: { shiftName, startTime, endTime, plant },
				};
			},
		}),
		updateShiftDefinition: builder.mutation({
			query({ id, shiftName, startTime, endTime, plant }) {
				return {
					url: `operation/shift/definition`,
					method: 'PUT',
					body: { id, shiftName, startTime, endTime, plant },
				};
			},
		}),
		deleteShiftDefinition: builder.mutation({
			query({ id }) {
				return {
					url: `operation/shift/definition`,
					method: 'DELETE',
					body: { id },
				};
			},
		}),
		fetchUsers: builder.query({
			query: () => `user`,
			transformResponse: (data) => {
				return data.map((elem) => ({ id: elem?._id, ...elem }));
			},
		}),
		updateUser: builder.mutation({
			query(body) {
				return {
					url: `user/update`,
					method: 'POST',
					body,
				};
			},
		}),
		checkinUser: builder.mutation({
			query({ plantName, plantCode, line, machine }) {
				return {
					url: `operation/operator/check-in`,
					method: 'POST',
					body: { plantName, plantCode, line, machine },
				};
			},
		}),
		checkoutUser: builder.mutation({
			query() {
				return {
					url: `operation/operator/check-out`,
					method: 'POST',
				};
			},
		}),
		fetchCheckinStatus: builder.query({
			query() {
				return {
					url: `operation/operator/check-in`,
				};
			},
		}),
		fetchShiftPlans: builder.query({
			query(data) {
				return {
					url: `operation/shift/planning`,
					params: data,
				};
			},
		}),
		fetchShiftPlanningCodes: builder.query({
			query: () => `operation/shift/planning/code`,
			transformResponse: (data) => {
				return data.map((elem) => ({ id: elem?._id, ...elem }));
			},
		}),
		createShiftPlans: builder.mutation({
			query(data) {
				return {
					url: `operation/shift/planning`,
					method: 'POST',
					body: data,
				};
			},
		}),
		fetchProductionPlan: builder.query({
			query(data) {
				return {
					url: `operation/production`,
					params: data,
				};
			},
		}),
		startProduction: builder.mutation({
			query(data) {
				return {
					url: `operation/production/start`,
					method: 'POST',
					body: data,
				};
			},
		}),
		endProduction: builder.mutation({
			query(data) {
				return {
					url: `operation/production/end`,
					method: 'POST',
				};
			},
		}),
		fetchStoppageCodes: builder.query({
			query(data) {
				return {
					url: `operation/stoppage/code`,
					params: data,
				};
			},
			transformResponse: (data) => {
				return data.map((elem) => ({ id: elem?._id, ...elem }));
			},
		}),
		fetchStoppageCodeHierarchy: builder.query({
			query({ language = 'en' }) {
				return {
					url: `operation/stoppage/code/hierarchy`,
					params: { language },
				};
			},
		}),
		createStoppageCode: builder.mutation({
			query(data) {
				return {
					url: `operation/stoppage/code`,
					method: 'POST',
					body: data,
				};
			},
		}),
		deleteStoppageCode: builder.mutation({
			query({ stoppageCodeId }) {
				return {
					url: `operation/stoppage/code`,
					method: 'DELETE',
					body: { stoppageCodeId },
				};
			},
		}),
		updateStoppageCode: builder.mutation({
			query(data) {
				return {
					url: `operation/stoppage/code`,
					method: 'PUT',
					body: data,
				};
			},
		}),
		cloneStoppageCode: builder.mutation({
			query({ stoppageCodeId, line, targetLine }) {
				return {
					url: `operation/stoppage/code/clone`,
					method: 'POST',
					body: { stoppageCodeId, line, targetLine },
				};
			},
		}),
		fetchLineStoppages: builder.query({
			query({ line, startTime, endTime }) {
				return {
					url: `operation/stoppage/line`,
					params: { line, startTime, endTime },
				};
			},
			transformResponse: (data) => {
				return data.map((elem) => ({ id: elem?._id, ...elem }));
			},
		}),
		updateLineStoppage: builder.mutation({
			query({ id, stoppageCodeId }) {
				return {
					url: `operation/stoppage/line`,
					method: 'PATCH',
					body: { id, stoppageCodeId },
				};
			},
		}),
		fetchMachineStoppages: builder.query({
			query({ line, machine, startTime, endTime }) {
				return {
					url: `operation/stoppage/machine`,
					params: { line, machine, startTime, endTime },
				};
			},
			transformResponse: (data) => {
				return data.map((elem) => ({ id: elem?._id, ...elem }));
			},
		}),
		updateMachineStoppage: builder.mutation({
			query({ id, stoppageCodeId }) {
				return {
					url: `operation/stoppage/machine`,
					method: 'PATCH',
					body: { id, stoppageCodeId },
				};
			},
		}),
		validateUserAction: builder.mutation({
			query({ code }) {
				return {
					url: `user/action/validate`,
					method: 'POST',
					body: { code },
				};
			},
		}),
		setUserPincode: builder.mutation({
			query({ code }) {
				return {
					url: `user/pincode`,
					method: 'POST',
					body: { code },
				};
			},
		}),
		validateUserSession: builder.mutation({
			query({ code }) {
				return {
					url: `user/session/validate`,
					method: 'POST',
					body: { code },
				};
			},
		}),
		loginCallback: builder.mutation({
			query(data) {
				return {
					url: `login/callback`,
					method: 'POST',
					body: data,
				};
			},
		}),
		logoutCallback: builder.mutation({
			query() {
				return {
					url: `/user/logout`,
					method: 'POST',
					body: {},
				};
			},
		}),
	}),
});

export const {
	useFetchStoppageCodesQuery,
	useFetchStoppageCodeHierarchyQuery,
	useCreateStoppageCodeMutation,
	useDeleteStoppageCodeMutation,
	useUpdateStoppageCodeMutation,
	useCloneStoppageCodeMutation,
	useFetchProductionStatusQuery,
	useFetchLinesQuery,
	useFetchUsersQuery,
	useUpdateUserMutation,
	useFetchShiftDefinitionsQuery,
	useCreateShiftDefinitionMutation,
	useDeleteShiftDefinitionMutation,
	useUpdateShiftDefinitionMutation,
	useCheckinUserMutation,
	useCheckoutUserMutation,
	useFetchShiftPlanningCodesQuery,
	useFetchShiftPlansQuery,
	useCreateShiftPlansMutation,
	useFetchProductionPlanQuery,
	useStartProductionMutation,
	useEndProductionMutation,
	useFetchLineStoppagesQuery,
	useUpdateLineStoppageMutation,
	useFetchMachineStoppagesQuery,
	useUpdateMachineStoppageMutation,
	useValidateUserActionMutation,
	useSetUserPincodeMutation,
	useLoginCallbackMutation,
	useValidateUserSessionMutation,
	useLogoutCallbackMutation,
	useFetchActiveShiftQuery,
	useFetchProductionStatusProductsQuery,
	useFetchCheckinStatusQuery,
} = API;
