import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { StrictMode } from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import { Provider } from 'react-redux';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

import reportWebVitals from './reportWebVitals';
import './index.css';
import Routes from './routes';
import { store } from './state/';
import resources from './translations.json';
import { msalConfig } from './authConfig';

const isProd = process.env.NODE_ENV === 'production';
const msalInstance = new PublicClientApplication(msalConfig);

moment.updateLocale('en', {
	week: {
		dow: 1, // start week on Mondays
	},
});

i18n
	.use(initReactI18next)
	.use(LanguageDetector)
	.init({
		resources,
		detection: {
			order: [
				'querystring',
				'cookie',
				'navigator',
				'localStorage',
				'sessionStorage',
				'htmlTag',
				'path',
				'subdomain',
			],
		},
		react: {
			useSuspense: false,
		},
		fallbackLng: 'en',
		debug: !isProd,
	});

const theme = createTheme({
	palette: {
		primary: {
			main: '#004B93',
			accent: 'aliceblue',
		},
		secondary: {
			main: grey[200],
		},
	},
});

const Root = () => {
	return (
		<StrictMode>
			<ThemeProvider theme={theme}>
				<LocalizationProvider dateAdapter={AdapterMoment}>
					<MsalProvider instance={msalInstance}>
						<Provider store={store}>
							<BrowserRouter>
								<Routes />
							</BrowserRouter>
						</Provider>
					</MsalProvider>
				</LocalizationProvider>
			</ThemeProvider>
		</StrictMode>
	);
};

render(<Root />, document.getElementById('root'));

if (!isProd) {
	import('./eruda').then(({ default: eruda }) => {
		reportWebVitals(console.log);
	});

	if (module.hot) module.hot.accept();
}
