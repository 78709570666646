import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
	Drawer,
	drawerWidth,
	Header,
	LoginBox,
	WrappedPinBox,
	Toast,
} from './components/';
import {
	AbilityContext,
	useLoginCallbackMutation,
	setUserRole,
	useSetUserPincodeMutation,
	useValidateUserSessionMutation,
	setUserToken,
	useFetchCheckinStatusQuery,
	setUserStation,
	usePlant,
} from './state/';
import { defineAbilityFor } from './ability';
import { loginRequest } from './authConfig';

const Wrapper = styled(Box)(({ theme }) => ({
	minWidth: `calc(100% - ${drawerWidth}px)`,
	marginLeft: `${drawerWidth}px`,
	display: 'flex',
	flexDirection: 'column',
}));

const App = () => {
	const { t } = useTranslation();
	const [needsPin, setNeedsPin] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const [isLoggedIn, setIsLoggedIn] = useState(null);
	const pageTitle = useSelector((state) => state.ui.pageTitle);
	const userRole = useSelector((state) => state.user.role);
	const userToken = useSelector((state) => state.user.token);
	const dispatch = useDispatch();
	const msal = useMsal();
	usePlant();
	const [onLoginCallback, { isLoading }] = useLoginCallbackMutation();
	const [onSetUserPin, { isLoading: isSetting }] = useSetUserPincodeMutation();
	const [onValidateSession, { isLoading: isValidating }] =
		useValidateUserSessionMutation();
	const { data: checkinStatus, error: fetchCheckinStatusError } =
		useFetchCheckinStatusQuery({
			skip: !Boolean(userToken),
		});

	useEffect(() => {
		if (userToken) {
			dispatch(setUserStation(checkinStatus));
		}
	}, [userToken]);

	useEffect(() => {
		const sendLoginCallback = async () => {
			if (!Boolean(isLoggedIn) && msal.accounts[0]) {
				try {
					const result = await onLoginCallback(
						msal.accounts[0].idTokenClaims
					).unwrap();
					const {
						accessToken,
						role,
						// needsToSetPinCode,
						// needsToValidateSession,
					} = result;
					if (role === 'operator') {
						setNeedsPin(true);
					}

					dispatch(setUserToken(accessToken));
					dispatch(setUserRole(role));
					setIsLoggedIn(result);
				} catch (error) {
					setErrorMessage(t('Try again'));
				}
			}
		};

		sendLoginCallback();
	}, [msal.accounts]);

	const onLogin = async (role) => {
		try {
			// const result = await msal.instance.loginRedirect(loginRequest);
			console.log({ login: msal.accounts[0]?.idTokenClaims });
			if (role === 'operator') setNeedsPin(true);
			dispatch(setUserRole(role));
		} catch (error) {}
	};

	const onPin = async (pinCode) => {
		const role = isLoggedIn?.role;
		const needsToSetPinCode = isLoggedIn?.needsToSetPinCode;

		if (role === 'operator' && needsToSetPinCode) {
			try {
				const result = await onSetUserPin({ code: pinCode }).unwrap();
				setNeedsPin(false);
			} catch (setUserPinError) {
				setErrorMessage(setUserPinError?.data?.message);
			}
		}

		if (role === 'operator' && !needsToSetPinCode) {
			try {
				const result = await onValidateSession({ code: pinCode }).unwrap();
				setNeedsPin(false);
			} catch (validateSessionError) {
				setErrorMessage(validateSessionError?.data?.message);
			}
		}

		setNeedsPin(false);
	};

	return (
		<>
			{Boolean(userRole) && !needsPin ? (
				<AbilityContext.Provider value={defineAbilityFor(userRole)}>
					<Box
						component='main'
						sx={{ flexGrow: 1, bgcolor: 'background.default' }}
					>
						<CssBaseline />
						<Drawer />
						<Wrapper>
							<Header title={pageTitle} />
							<Outlet />
						</Wrapper>
					</Box>
				</AbilityContext.Provider>
			) : needsPin ? (
				<WrappedPinBox onPin={onPin} />
			) : (
				<LoginBox onSignIn={onLogin} />
			)}
			<Toast
				isOpen={Boolean(errorMessage)}
				onClose={() => setErrorMessage(null)}
				severity='error'
			>
				{errorMessage}
			</Toast>
		</>
	);
};

export default App;
