import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import ButtonGroup from "@mui/material/ButtonGroup";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import moment from "moment";
import { useState } from "react";

import { FormWrapper } from ".";
import { Button, ListItem as MyList } from "..";
import { formatStartTime } from "../../util";
import { useLanguageSuffix } from "../../state";

export default ({ onSubmit = () => {}, onCancel = () => {}, selected }) => {
  const { t } = useTranslation();
  const [selectedReason, setSelectedReason] = useState("");
  const { desuffixize } = useLanguageSuffix();

  const onSave = (event) => {
    event.preventDefault();
    event.stopPropagation();

    onSubmit({ stoppageCodeId: selectedReason });
  };

  return (
    <FormWrapper component={Box}>
      <Box>
        <MyList
          entity={{
            startTime: formatStartTime(moment(selected?.startTime)),
            Duration: selected?.duration,
            Type: selected?.type,
          }}
        />
      </Box>
      <Box>
        <List>
          {selected?.originalReason?.map((reason) => {
            const { reasonLabel, stoppageCodeId } = desuffixize(reason, [
              "reasonLabel",
            ]);

            return (
              <ListItem key={stoppageCodeId}>
                <ListItemButton
                  selected={selectedReason === stoppageCodeId}
                  onClick={() => setSelectedReason(stoppageCodeId)}
                >
                  <ListItemText primary={reasonLabel} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Box>
      <ButtonGroup
        variant="outlined"
        component="span"
        size="large"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
        }}
      >
        <Button onClick={onCancel}>{t("Cancel")}</Button>
        <Button onClick={onSave} color="success" variant="contained">
          {t("Save")}
        </Button>
      </ButtonGroup>
    </FormWrapper>
  );
};
