import { useState, useEffect, useContext } from 'react';
import inRange from 'lodash/inRange';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import _Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';

import LanguageOutlined from '@mui/icons-material/LanguageOutlined';
import LogoutOutlined from '@mui/icons-material/LogoutOutlined';
import { useMsal } from '@azure/msal-react';

import {
	setPageTitle,
	setUserStation,
	setUserRole,
	AbilityContext,
	useLogoutCallbackMutation,
} from '../state/';
import { navigation } from '../routes';
import locales from '../translations.json';

export const drawerWidth = 240;

export const Drawer = () => {
	const [[openIndex, openSub], setOpenIndex] = useState([null, null]);
	const [onLogoutCallback] = useLogoutCallbackMutation();
	const dispatch = useDispatch();
	const { i18n, t } = useTranslation();
	const location = useLocation();
	const ability = useContext(AbilityContext);
	const msal = useMsal();

	useEffect(() => {
		const path = location.pathname.replace('/', '');
		const [child, ...indexes] = findIndexes(path, navigation);

		dispatch(setPageTitle(child?.text));
		setOpenIndex(([parentIdx, childIdx]) => {
			if (parentIdx !== indexes[0] || childIdx !== indexes[1]) {
				return indexes;
			}

			return [parentIdx, childIdx];
		});
	}, [location]);

	const changeLocale = () => {
		const { resolvedLanguage } = i18n;
		const languages = Object.keys(locales);
		const index = languages.findIndex((lang) => lang === resolvedLanguage);

		if (index !== -1) {
			const nextLang = languages[(index + 1) % languages.length];

			i18n.changeLanguage(nextLang);
		} else {
			i18n.changeLanguage(languages[0]);
		}
	};

	const onLogout = async () => {
		let accounts;
		try {
			const sessionKeys = Object.keys(window.sessionStorage);

			for (const key of sessionKeys) {
				const item = sessionStorage.getItem(key);

				try {
					const parsed = JSON.parse(item);
					if (parsed.credentialType === 'AccessToken') {
						accounts = parsed.homeAccountId;
					}
				} catch (e) {}
			}

			try {
				const backendResponse = await onLogoutCallback().unwrap();
			} catch (error) {
				if (error.status !== 401) throw error;
				return;
			}

			const azureResponse = await msal.instance.logoutRedirect({
				account: msal.instance.getAccountByHomeId(accounts),
			});

			dispatch(setUserStation(null));
			dispatch(setUserRole(null));
		} catch (error) {}
	};

	return (
		<_Drawer
			sx={{
				width: drawerWidth,
				flexShrink: 0,
				'& .MuiDrawer-paper': {
					width: drawerWidth,
					boxSizing: 'border-box',
				},
				a: {
					textDecoration: 'none',
					color: 'slategrey',
				},
			}}
			variant='permanent'
			anchor='left'
		>
			<Typography
				variant='h6'
				noWrap
				component='div'
				sx={{
					marginInline: 'auto',
					mt: 2,
					mb: 2,
				}}
			>
				<Link to='/' onClick={() => dispatch(setPageTitle(null))}>
					{t('Twinmaster')}
				</Link>
			</Typography>
			<Divider sx={{ borderWidth: '.1rem' }} />
			<List>
				{navigation.map(({ text, Icon, children, type = 'Operator' }, idx) => {
					const isSelected = openIndex === idx;
					const canView = ability.can('view', type);

					return (
						canView && (
							<ListItemButton
								key={text}
								selected={isSelected}
								onClick={() =>
									isSelected
										? setOpenIndex([null, null])
										: setOpenIndex([idx, null])
								}
								sx={{
									'&:hover': {
										backgroundColor: 'primary.accent',
									},
								}}
							>
								<ListItemIcon>{<Icon fontSize='large' />}</ListItemIcon>
								<ListItemText primary={text} />
								{children ? isSelected ? <ExpandLess /> : <ExpandMore /> : null}
							</ListItemButton>
						)
					);
				})}
				<Divider sx={{ borderWidth: '.1rem' }} />
				<Collapse
					in={inRange(openIndex, navigation.length)}
					timeout='auto'
					unmountOnExit
				>
					<List component='div' disablePadding>
						{navigation[openIndex]?.children?.map?.(
							({ text, to, type = 'Operator' }, idx) => {
								const canView = ability.can('view', type);

								return (
									canView && (
										<Link to={to} key={text}>
											<ListItemButton
												selected={openSub === idx}
												onClick={() => {
													setOpenIndex(([prev]) => [prev, idx]);
												}}
												sx={{
													'&:hover': {
														backgroundColor: 'primary.accent',
													},
												}}
											>
												<ListItemText primary={t(text)} />
											</ListItemButton>
										</Link>
									)
								);
							}
						)}
					</List>
				</Collapse>
			</List>
			<List sx={{ mt: 'auto' }}>
				<ListItemButton onClick={changeLocale}>
					<ListItemIcon>
						<LanguageOutlined fontSize='large' />
					</ListItemIcon>
					<ListItemText primary={t('Language')} />
				</ListItemButton>
				<ListItemButton onClick={onLogout}>
					<ListItemIcon>
						<LogoutOutlined fontSize='large' />
					</ListItemIcon>
					<ListItemText primary={t('Logout')} />
				</ListItemButton>
			</List>
		</_Drawer>
	);
};

function findIndexes(path, routes = []) {
	let parentIdx = 0;
	let childIdx;
	let done = false;

	for (const route of routes) {
		const { children } = route;

		if (children) {
			childIdx = 0;
			for (const child of children) {
				const { to } = child;

				if (path === to) {
					done = true;
					break;
				}

				childIdx += 1;
			}
		}

		if (done) break;
		parentIdx += 1;
	}

	return [routes[parentIdx]?.children[childIdx], parentIdx, childIdx];
}
