export const capitalizeFirstChar = (string) =>
  string
    .split("")
    .map((char, idx) => {
      if (idx === 0) return char.toUpperCase();

      return char;
    })
    .join("");

export const formatDate = (moment) => {
  return moment.format("YYYY-MM-DD") + "T00:00:00.000Z";
};

export const formatStartTime = moment => {
  return moment.format("YYYY-MM-DD HH:mm:ss");
}