import { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useFuzzy } from "react-use-fuzzy";
import ButtonGroup from "@mui/material/ButtonGroup";
import pick from "lodash.pick";
import moment from "moment";

import { Wrapper } from ".";
import {
  Footer,
  Button,
  Dialog,
  Toolbar,
  Toast,
  ListItem,
} from "../components/";
import { AddShift, EditShift } from "../components/forms";
import {
  useDeleteShiftDefinitionMutation,
  useUpdateShiftDefinitionMutation,
  useCreateShiftDefinitionMutation,
  usePlant,
  useShifts,
} from "../state/";

export default () => {
  const { t } = useTranslation();
  const { currentPlant } = usePlant();
  const {
    shiftDefinitions,
    error: fetchShiftDefinitionsError,
    isLoading,
    refetch,
  } = useShifts();
  const [onCreateShiftDefinition, { isLoading: isCreating }] =
    useCreateShiftDefinitionMutation();
  const [onUpdateShiftDefinition, { isLoading: isUpdating }] =
    useUpdateShiftDefinitionMutation();
  const [onDeleteShiftDefinition, { isLoading: isDeleting }] =
    useDeleteShiftDefinitionMutation();
  const [selected, setSelected] = useState(null);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [hasError, setHasError] = useState(
    Boolean(fetchShiftDefinitionsError) &&
      Object.keys(fetchShiftDefinitionsError).length
  );

  const {
    result: rows = [],
    keyword,
    search,
  } = useFuzzy(shiftDefinitions, {
    keys: ["shiftName"],
    isCaseSensitive: true,
    minMatchCharLength: 2,
    shouldSort: true,
    findAllMatches: true,
  });
  const columns = [
    { field: "id", hide: true },
    { field: "shiftName" },
    { field: "startTime" },
    { field: "endTime" },
  ].map(({ field, ...rest }) => ({
    field,
    headerName: t(field),
    flex: true,
    align: "center",
    headerAlign: "center",
    ...rest,
  }));

  const onRowClick = (params, event) => {
    const { row } = params;
    const { target } = event;

    if (row.id !== selected?.id) {
      setSelected(row);
      setTimeout(() => {
        target.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }, 30);
    }
  };

  const onAddShift = async (data) => {
    const { startTime, endTime, shiftName } = data;

    try {
      const result = await onCreateShiftDefinition({
        startTime: moment(startTime).format("HH:mm"),
        endTime: moment(endTime).format("HH:mm"),
        shiftName,
        plant: currentPlant,
      }).unwrap();

      refetch();
    } catch (addShiftError) {
      setHasError(addShiftError?.data?.message);
    } finally {
      setSelected(null);
      setIsAddOpen(false);
    }
  };

  const onEditShift = async (data) => {
    const { startTime, endTime, shiftName } = data;
    const { id } = selected;

    try {
      const result = await onUpdateShiftDefinition({
        startTime: moment(startTime).format("HH:mm"),
        endTime: moment(endTime).format("HH:mm"),
        shiftName,
        plant: currentPlant,
        id,
      }).unwrap();

      refetch();
    } catch (editShiftError) {
      setHasError(editShiftError?.data?.message);
    } finally {
      setSelected(null);
      setIsEditOpen(false);
    }
  };

  const onDeleteShift = async () => {
    const { id } = selected;

    try {
      const result = await onDeleteShiftDefinition({ id }).unwrap();

      refetch();
    } catch (deleteShiftError) {
      setHasError(deleteShiftError?.data?.message);
    } finally {
      setSelected(null);
      setIsConfirmOpen(false);
    }
  };

  return (
    <Wrapper component="section" flex>
      <div style={{ flexGrow: 1 }}>
        <DataGrid
          rows={
            Boolean(fetchShiftDefinitionsError) || isLoading
              ? []
              : rows?.[0]?.item
              ? rows.map(({ item }) => item)
              : rows
          }
          columns={columns}
          autoHeight
          pageSize={25}
          components={{ Toolbar }}
          componentsProps={{
            toolbar: { search, keyword },
          }}
          sx={{
            "& .MuiDataGrid-cell:focus": {
              outline: "none",
            },
            "& .MuiDataGrid-columnHeader:focus": {
              outline: "none",
            },
          }}
          onRowClick={onRowClick}
        />
      </div>
      <Footer>
        <Button onClick={() => setIsAddOpen(true)}>{t("Add Shift")}</Button>
        <Button
          onClick={() => setIsEditOpen(true)}
          disabled={!Boolean(selected)}
        >
          {t("Edit Shift")}
        </Button>
        <Button
          onClick={() => setIsConfirmOpen(true)}
          disabled={!Boolean(selected)}
        >
          {t("Delete Shift")}
        </Button>
      </Footer>
      <Dialog
        isOpen={isAddOpen}
        handleClose={() => setIsAddOpen(false)}
        title={t("Add Shift")}
      >
        <AddShift onSubmit={onAddShift} onCancel={() => setIsAddOpen(false)} />
      </Dialog>
      <Dialog
        isOpen={isEditOpen}
        handleClose={() => setIsEditOpen(false)}
        title={t("Edit Shift")}
      >
        <EditShift
          shift={selected}
          onSubmit={onEditShift}
          onCancel={() => setIsEditOpen(false)}
        />
      </Dialog>
      <Dialog
        isOpen={isConfirmOpen}
        handleClose={() => setIsConfirmOpen(false)}
        title={t("Are you sure?")}
        actions={
          <ButtonGroup
            variant="outlined"
            component="span"
            size="large"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Button onClick={() => setIsConfirmOpen(false)}>{t("No")}</Button>
            <Button onClick={onDeleteShift} color="success" variant="contained">
              {t("Yes")}
            </Button>
          </ButtonGroup>
        }
      >
        <ListItem
          heading={t("Deleting")}
          entity={pick(selected, ["shiftName", "plant"])}
        />
      </Dialog>
      <Toast
        isOpen={Boolean(hasError)}
        onClose={() => setHasError(false)}
        severity="error"
      >
        {t(fetchShiftDefinitionsError?.data?.message || hasError)}
      </Toast>
    </Wrapper>
  );
};
