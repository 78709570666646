import styled from "@emotion/styled";
import _Button from "@mui/material/Button";

export const Button = styled(_Button)(({ theme }) => ({
  fontSize: ".7rem",
  [theme.breakpoints.down("md")]: {
    fontSize: ".5rem",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "1rem",
  },
}));

Button.defaultProps = {
  color: "secondary",
  variant: "contained",
};
