import { Routes as _Routes, Route, Navigate } from 'react-router-dom';
import FindInPageOutlined from '@mui/icons-material/FindInPageOutlined';
import AutoGraphOutlined from '@mui/icons-material/AutoGraphOutlined';
import NotificationsActiveOutlined from '@mui/icons-material/NotificationsActiveOutlined';
import TouchAppOutlined from '@mui/icons-material/TouchAppOutlined';
import { useTranslation } from 'react-i18next';

import App from './App';
import {
	OperatorCheckin,
	ProductionStatus,
	StartEndProduction,
	Stoppages,
	// DefineLineStoppage,
	ShiftDefinition,
	StoppageCodeManagement,
	UserManagement,
	ShiftPlanning,
	WatchPerformance,
} from './views/';
import { Wrapper } from './components';

export const navigation = [
	{
		text: 'TwinWatch',
		Icon: FindInPageOutlined,
		children: [
			{ text: 'Line Performance', to: 'watch-performance' },
			{ text: 'Line Equipment', to: 'line-equipment' },
			{ text: 'Syrup Room', to: 'syrup-room' },
		],
	},
	{
		text: 'TwinSense',
		Icon: AutoGraphOutlined,
		type: 'Manager',
		children: [
			{ text: 'Line Performance', to: 'sense-performance' },
			{ text: 'Line Stoppage', to: 'line-stoppage' },
			{ text: 'CIP', to: 'cip' },
		],
	},
	{
		text: 'TwinSight',
		Icon: NotificationsActiveOutlined,
	},
	{
		text: 'Operation',
		Icon: TouchAppOutlined,
		children: [
			{
				text: 'Operator Checkin',
				to: 'operator-checkin',
				type: 'OperatorOnly',
			},
			{ text: 'Shift Definition', to: 'shift-definition', type: 'Manager' },
			{ text: 'Shift Planning', to: 'shift-planning', type: 'Manager' },
			{ text: 'Start/End Production', to: 'start-end-production' },
			{
				text: 'Stoppages',
				to: 'stoppages',
			},
			// {
			//   text: "Define Line Stoppage",
			//   to: "define-line-stoppage",
			//   type: "Manager",
			// },
			{
				text: 'Stoppage Code Management',
				to: 'stoppage-code-management',
				type: 'Admin',
			},
			{ text: 'User Management', to: 'user-management', type: 'Manager' },
			{ text: 'Production Status', to: 'production-status' },
		],
	},
];

const Routes = () => {
	const { t } = useTranslation();

	return (
		<_Routes>
			<Route path='/' element={<App />}>
				<Route path='watch-performance' element={<WatchPerformance />} />
				<Route path='production-status' element={<ProductionStatus />} />
				<Route path='start-end-production' element={<StartEndProduction />} />
				<Route path='operator-checkin' element={<OperatorCheckin />} />
				<Route path='stoppages' element={<Stoppages />} />
				<Route
					path='api/v1/logout/callback'
					element={<Navigate replace to='/' />}
				/>
				{/* <Route path="define-line-stoppage" element={<DefineLineStoppage />} /> */}
				<Route path='shift-definition' element={<ShiftDefinition />} />
				<Route path='shift-planning' element={<ShiftPlanning />} />
				<Route
					path='stoppage-code-management'
					element={<StoppageCodeManagement />}
				/>
				<Route path='user-management' element={<UserManagement />} />
				{navigation.map(({ children }) => {
					return children?.map(({ to }) => {
						return (
							<Route
								path={to}
								element={
									<Wrapper sx={{ height: '80vh' }}>
										<h1>{t('Under Construction')}</h1>
									</Wrapper>
								}
							/>
						);
					});
				})}
				<Route
					path='*'
					element={
						<Wrapper sx={{ height: '80vh' }}>
							<h1>404</h1>
						</Wrapper>
					}
				/>
			</Route>
		</_Routes>
	);
};

export default Routes;
