import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ButtonGroup from "@mui/material/ButtonGroup";

import { FormWrapper } from ".";
import { TextInput, TimeInput, Button } from "..";

export default ({ onSubmit = () => {}, onCancel = () => {} }) => {
  const { t } = useTranslation();
  const { control, handleSubmit, reset } = useForm({
    mode: "onSubmit",
    defaultValues: {
      startTime: Date.now(),
      endTime: Date.now(),
      shiftName: "",
    },
  });

  const handleFormSubmit = (data, event) => {
    event.preventDefault();
    onSubmit(data);
    handleReset();
  };

  const handleReset = () => {
    reset({ shiftName: "" });
  };

  return (
    <FormWrapper onSubmit={handleSubmit(handleFormSubmit)}>
      <Box>
        <Typography color="text.secondary" component="span" variant="h6">
          {t("startTime")}
        </Typography>
        <Controller
          name="startTime"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TimeInput error={!field.value?.length} {...field} />
          )}
        />
      </Box>
      <Box>
        <Typography color="text.secondary" component="span" variant="h6">
          {t("endTime")}
        </Typography>
        <Controller
          name="endTime"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TimeInput error={!field.value?.length} {...field} />
          )}
        />
      </Box>

      <Box>
        <Typography color="text.secondary" component="span" variant="h6">
          {t("shiftName")}
        </Typography>
        <Controller
          name="shiftName"
          control={control}
          rules={{
            required: true,
            maxLength: 25,
            // validate: (input) => new RegExp("^[a-zA-Z0-9 ]+$", "i").test(input),
          }}
          render={() => (
            <Controller
              name="shiftName"
              control={control}
              render={({ field }) => (
                <TextInput
                  {...field}
                  onChange={(e) => {
                    const value = e.target.value;
                    const isValid =
                      new RegExp("^[a-zA-Z0-9 ]+$", "i").test(value) &&
                      value.length < 26;

                    if (isValid) field.onChange(value);
                  }}
                />
              )}
            />
          )}
        />
      </Box>
      <ButtonGroup
        variant="outlined"
        component="span"
        size="large"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
        }}
      >
        <Button onClick={onCancel}>{t("Cancel")}</Button>
        <Button onClick={handleReset}>{t("Reset")}</Button>
        <Button type="submit" color="success" variant="contained">
          {t("Add")}
        </Button>
      </ButtonGroup>
    </FormWrapper>
  );
};
