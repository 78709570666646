import { useState } from "react";
import _Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { Select } from ".";

export const Table = ({
  title = "",
  columns = [],
  rows = [],
  STOPPAGE_CODES,
  value = [],
  onChange,
  ...rest
}) => {
  const [stoppageCodes, setStoppageCodes] = useState(value);

  const onSelect = ({ date, shiftStatus, shiftId, lineId }) => {
    setStoppageCodes((prev) => {
      const newState = [
        { shiftPlanDate: date, shiftStatus, shiftId, lineId },
        ...prev,
      ];

      onChange(newState);

      return newState;
    });
  };

  const getShiftStatus = (elem) => {
    const found = stoppageCodes.find(({ shiftPlanDate, shiftId }) => {
      return shiftPlanDate === elem.date && shiftId === elem.shiftId;
    });

    return found?.shiftStatus ?? elem.shiftStatus;
  };

  return (
    <TableContainer component={Paper}>
      <_Table {...rest}>
        <TableHead>
          <TableRow>
            <TableCell align="center" sx={{ padding: "1rem 0" }}>
              {title}
            </TableCell>
            {columns.map((text) => {
              return (
                <TableCell key={text} align="center">
                  {text}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(([title, ...rest]) => (
            <TableRow key={title}>
              <TableCell component="th" scope="row" align="center">
                {title}
              </TableCell>
              {rest?.map((elem, idx) => {
                const { isEditable, shiftStatus, viewColor } = elem;

                return (
                  <TableCell
                    key={`${elem}-${idx}`}
                    align="center"
                    sx={{ padding: "1rem 0" }}
                  >
                    {!isEditable ? (
                      <i
                        style={{
                          color: Boolean(viewColor) ? viewColor : "initial",
                        }}
                      >
                        <b>{shiftStatus}</b>
                      </i>
                    ) : (
                      <Select
                        value={getShiftStatus(elem)}
                        options={STOPPAGE_CODES}
                        onSelect={(shiftStatus) =>
                          onSelect({ ...elem, shiftStatus })
                        }
                        sx={{ width: "6rem", marginRight: "auto" }}
                        style = {{
                          backgroundColor: viewColor,
                          color: "#eee",
                          fontSize : "10px",
                        }}
                        variant="filled"
                      />
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </_Table>
    </TableContainer>
  );
};
