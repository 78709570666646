import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	station: 'FILLER',
	role: 'key-user',
	token: null,
	USER_ROLES: ['non-operator', 'operator', 'key-user'],
};

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setUserStation: (state, { payload }) => {
			state.station = payload;
		},
		setUserToken: (state, { payload }) => {
			state.token = payload;
		},
		setUserRole: (state, { payload }) => {
			state.role = payload;
		},
	},
});

export const { setUserStation, setUserToken, setUserRole } = userSlice.actions;

export default userSlice.reducer;
