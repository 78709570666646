import { createContext } from "react";
import { configureStore } from "@reduxjs/toolkit";
// import { setupListeners } from "@reduxjs/toolkit/query";

import { API } from "./apiSlice";
import ui from "./uiSlice";
import user from "./userSlice";

export * from "./hooks";
export * from "./uiSlice";
export * from "./userSlice";
export * from "./apiSlice";

export const AbilityContext = createContext();

export const store = configureStore({
  reducer: {
    [API.reducerPath]: API.reducer,
    ui,
    user,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(API.middleware),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
// setupListeners(store.dispatch)
