import { useState, useMemo } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { faker } from "@faker-js/faker";
import { useFuzzy } from "react-use-fuzzy";
import ButtonGroup from "@mui/material/ButtonGroup";
import { useLocation } from "react-router-dom";
import pick from "lodash.pick";

import { Wrapper } from ".";
import {
  Footer,
  Button,
  Toolbar,
  Dialog,
  Toast,
  ListItem,
} from "../components/";
import { AddReason, EditReason, CloneReason } from "../components/forms";
import {
  useCloneStoppageCodeMutation,
  useCreateStoppageCodeMutation,
  useDeleteStoppageCodeMutation,
  useUpdateStoppageCodeMutation,
  useStoppages,
  usePlant,
  useLanguageSuffix,
} from "../state/";

const REASONS = Array.from({ length: 30 }, () => faker.random.words(3));
const KEY_NAMES = ["stoppageType", "groupType", "groupCode", "stoppageName"];

export default () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const [line, setLine] = useState("");
  const [machine, setMachine] = useState("");
  const { suffixize } = useLanguageSuffix();
  const { allLines: LINES, machinesByLine } = usePlant();
  const MACHINES = useMemo(
    () => machinesByLine?.[line]?.map?.(({ machine }) => machine),
    [line, machinesByLine]
  );

  const {
    stoppageCodes: data,
    stoppageCodeHierarchy,
    stoppageTypes: TYPES,
    stoppageGroups: GROUPS,
    stoppageSubgroups: SUBGROUPS,
    error: fetchStoppageCodesError,
    isLoading,
    refetch,
  } = useStoppages({ line, machine });
  const [onCreateStoppageCode, { isLoading: isCreating }] =
    useCreateStoppageCodeMutation();
  const [onUpdateStoppageCode, { isLoading: isUpdating }] =
    useUpdateStoppageCodeMutation();
  const [onDeleteStoppageCode, { isLoading: isDeleting }] =
    useDeleteStoppageCodeMutation();
  const [onCloneStoppageCode, { isLoading: isCloning }] =
    useCloneStoppageCodeMutation();
  const [selected, setSelected] = useState(null);
  const [hasError, setHasError] = useState(
    Boolean(fetchStoppageCodesError) &&
      Object.keys(fetchStoppageCodesError).length
  );
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isAddOpen, setIsAddOpen] = useState(Boolean(state?.isAddOpen));
  const [isEditOpen, setIsEditOpen] = useState(Boolean(state?.isEditOpen));
  const [isCloneOpen, setIsCloneOpen] = useState(Boolean(state?.isCloneOpen));
  const {
    result: rows = [],
    keyword,
    search,
  } = useFuzzy(data, {
    keys: KEY_NAMES,
    isCaseSensitive: true,
    minMatchCharLength: 2,
    shouldSort: true,
    findAllMatches: true,
  });
  const columns = [
    { field: "id", hide: true },
    { field: "Type", keyName: "groupType", flex: 0.3 },
    { field: "Group", keyName: "groupCode", flex: 0.2 },
    { field: "Subgroup", keyName: "stoppageType", flex: 0.2 },
    { field: "Reason", keyName: "stoppageName" },
  ].map(({ field, keyName, ...rest }) => ({
    field: keyName || field,
    headerName: t(field),
    flex: true,
    align: "center",
    headerAlign: "center",
    ...rest,
  }));

  const onRowClick = (params, event) => {
    const { row } = params;
    const { target } = event;

    if (row.id !== selected?.id) {
      setSelected(row);
      setTimeout(() => {
        target.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }, 30);
    }
  };

  const onAddReason = async (data) => {
    let {
      groupType,
      stoppageType,
      groupCode,
      explanation: stoppageName,
    } = data;

    let tempStoppageType = stoppageType;
    stoppageType = groupCode;
    groupCode = tempStoppageType;

    const toSend = suffixize({
      groupType,
      stoppageType,
      groupCode,
      stoppageName,
    });

    try {
      const result = await onCreateStoppageCode({
        ...toSend,
        isAutomaticStoppage: false,
        line,
        machine,
      }).unwrap();

      refetch();
    } catch (createStoppageCodeError) {
      setHasError(createStoppageCodeError?.data?.message);
    } finally {
      setSelected(null);
      setIsAddOpen(false);
    }
  };

  const onEditReason = async (data) => {
    let {
      type: groupType,
      group: stoppageType,
      subgroup: groupCode,
      reason: stoppageName,
    } = data;

    let tempStoppageType = stoppageType;
    stoppageType = groupCode;
    groupCode = tempStoppageType;

    const { id: stoppageCodeId } = selected;
    const toSend = suffixize({
      groupType,
      stoppageType,
      groupCode,
      stoppageName,
    });

    try {
      const result = await onUpdateStoppageCode({
        ...toSend,
        stoppageCodeId,
        line,
        machine,
      }).unwrap();

      refetch();
    } catch (editStoppageCodeError) {
      setHasError(editStoppageCodeError?.data?.message);
    } finally {
      setSelected(null);
      setIsEditOpen(false);
    }
  };

  const onCloneReason = async (data) => {
    const { targetLine } = data;
    const { id: stoppageCodeId } = selected;

    try {
      const result = await onCloneStoppageCode({
        targetLine,
        stoppageCodeId,
        line,
      }).unwrap();

      refetch();
    } catch (cloneStoppageCodeError) {
      setHasError(cloneStoppageCodeError?.data?.message);
    } finally {
      setSelected(null);
      setIsCloneOpen(false);
    }
  };

  const onDeleteReason = async () => {
    const { id: stoppageCodeId } = selected;

    try {
      const result = await onDeleteStoppageCode({ stoppageCodeId }).unwrap();

      refetch();
    } catch (deleteStoppageCodeError) {
      setHasError(deleteStoppageCodeError?.data?.message);
    } finally {
      setSelected(null);
      setIsConfirmOpen(false);
    }
  };

  return (
    <Wrapper component="section" flex>
      <div style={{ flexGrow: 1 }}>
        <DataGrid
          rows={
            Boolean(fetchStoppageCodesError) || isLoading
              ? []
              : rows?.[0]?.item
              ? rows.map(({ item }) => item)
              : rows
          }
          columns={columns}
          autoHeight
          pageSize={25}
          components={{ Toolbar }}
          componentsProps={{
            toolbar: {
              search,
              keyword,
              LINES,
              MACHINES,
              line,
              setLine,
              machine,
              setMachine,
            },
          }}
          sx={{
            "& .MuiDataGrid-cell:focus": {
              outline: "none",
            },
            "& .MuiDataGrid-columnHeader:focus": {
              outline: "none",
            },
          }}
          onRowClick={onRowClick}
        />
      </div>
      <Footer>
        <Button
          disabled={!Boolean(line) || !Boolean(machine)}
          onClick={() => setIsAddOpen(true)}
        >
          {t("Add Reason")}
        </Button>
        <Button
          onClick={() => setIsEditOpen(true)}
          disabled={!Boolean(selected)}
        >
          {t("Edit Reason")}
        </Button>
        <Button
          onClick={() => setIsCloneOpen(true)}
          disabled={!Boolean(selected)}
        >
          {t("Clone Reason")}
        </Button>
        <Button
          onClick={() => setIsConfirmOpen(true)}
          disabled={!Boolean(selected)}
        >
          {t("Delete Reason")}
        </Button>
      </Footer>
      <Dialog
        isOpen={isAddOpen}
        handleClose={() => setIsAddOpen(false)}
        title={t("Add Reason")}
      >
        <AddReason
          onSubmit={onAddReason}
          onCancel={() => setIsAddOpen(false)}
          line={line}
          machine={machine}
          TYPES={TYPES}
          GROUPS={GROUPS}
          SUBGROUPS={SUBGROUPS}
          REASONS={REASONS}
        />
      </Dialog>
      <Dialog
        isOpen={isEditOpen}
        handleClose={() => setIsEditOpen(false)}
        title={t("Edit Reason")}
      >
        <EditReason
          onSubmit={onEditReason}
          onCancel={() => setIsEditOpen(false)}
          selected={selected}
          TYPES={TYPES}
          GROUPS={GROUPS}
          SUBGROUPS={SUBGROUPS}
          REASONS={REASONS}
        />
      </Dialog>
      <Dialog
        isOpen={isCloneOpen}
        handleClose={() => setIsCloneOpen(false)}
        title={t("Clone Reason")}
      >
        <CloneReason
          onSubmit={onCloneReason}
          onCancel={() => setIsCloneOpen(false)}
          selected={pick(selected, KEY_NAMES)}
        />
      </Dialog>
      <Dialog
        isOpen={isConfirmOpen}
        handleClose={() => setIsConfirmOpen(false)}
        title={t("Are you sure?")}
        actions={
          <ButtonGroup
            variant="outlined"
            component="span"
            size="large"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Button onClick={() => setIsConfirmOpen(false)}>{t("No")}</Button>
            <Button
              onClick={onDeleteReason}
              color="success"
              variant="contained"
            >
              {t("Yes")}
            </Button>
          </ButtonGroup>
        }
      >
        <ListItem heading={t("Deleting")} entity={pick(selected, KEY_NAMES)} />
      </Dialog>
      <Toast
        isOpen={Boolean(hasError)}
        onClose={() => setHasError(false)}
        severity="error"
      >
        {t(fetchStoppageCodesError?.data?.message || hasError)}
      </Toast>
    </Wrapper>
  );
};
