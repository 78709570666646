import { useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ButtonGroup from "@mui/material/ButtonGroup";

import { FormWrapper } from ".";
import { Select, Button } from "..";

const LINES = ["PRD-01", "PRD-02", "PRD-03", "PRD-04", "PRD-05"];
const STATIONS = ["STATION1", "STATION2", "STATION3", "STATION4"];

export default ({ selected, onSubmit = () => {}, onCancel = () => {} }) => {
  const { t } = useTranslation();
  const [line, setLine] = useState("");
  const [station, setStation] = useState("");

  const handleSubmit = event => {
    event.preventDefault();
    onSubmit({ targetLine: line });
  };

  return (
    <FormWrapper>
      {selected && Object.entries(selected).map(([key, value]) => {
        if (key === "id") return null;

        return (
          <Box
            key={key}
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography color="text.secondary" component="span" variant="h6">
              {t(key)}
            </Typography>
            <Typography
              color="text.secondary"
              component="span"
              variant="body1"
              ml="auto"
            >
              {value}
            </Typography>
            <br />
          </Box>
        );
      })}
      <Box
        component="aside"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "2rem",
          marginTop: ".7rem",
        }}
      >
        <Select
          label={t("Line")}
          value={line}
          options={LINES}
          onSelect={setLine}
          sx={{ minWidth: "5rem" }}
          variant="standard"
        />
        <Select
          label={t("Station")}
          value={station}
          options={STATIONS}
          onSelect={setStation}
          sx={{ minWidth: "7rem" }}
          variant="standard"
        />
      </Box>
      <ButtonGroup
        variant="outlined"
        component="span"
        size="large"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
        }}
      >
        <Button onClick={onCancel}>{t("Cancel")}</Button>
        <Button onClick={handleSubmit} color="success" variant="contained">
          {t("Clone")}
        </Button>
      </ButtonGroup>
    </FormWrapper>
  );
};
