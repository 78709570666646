import { useMemo, useEffect, useCallback } from "react";
import groupBy from "lodash.groupby";
import { useTranslation } from "react-i18next";

import {
  useFetchLinesQuery,
  useFetchShiftDefinitionsQuery,
  useFetchStoppageCodesQuery,
  useFetchStoppageCodeHierarchyQuery,
  useFetchActiveShiftQuery,
} from ".";
import { capitalizeFirstChar } from "../util";

export const usePlant = () => {
  const {
    data: allMachines,
    error,
    isLoading,
    isFetching,
    requestId,
    refetch,
  } = useFetchLinesQuery(undefined, {
    refetchOnMountOrArgChange: 300, // seconds
  });

  const currentPlant = allMachines?.[0]?.plant;
  const machinesByLine = useMemo(
    () => groupBy(allMachines, ({ line }) => line),
    [requestId]
  );
  const allLines = Object.keys(machinesByLine);

  return {
    currentPlant,
    allMachines,
    allLines,
    machinesByLine,
    refetch,
    isLoading,
    isFetching,
    error,
  };
};

export const useShifts = ({ line } = {}) => {
  const {
    data: shiftDefinitions,
    error,
    isLoading,
    isFetching,
    refetch,
  } = useFetchShiftDefinitionsQuery();
  const { data: currentShift } = useFetchActiveShiftQuery(
    { line },
    { skip: !Boolean(line) }
  );

  return {
    shiftDefinitions,
    currentShift,
    refetch,
    isLoading,
    isFetching,
    error,
  };
};

export const useStoppages = (
  {
    line,
    machine,
    stoppageType = "",
    groupType = "",
    groupCode = "",
    showAutomaticStoppagesOnly = false,
    showInactiveCodes = true,
  } = {},
  { skip = !Boolean(line) || !Boolean(machine) } = {}
) => {
  const { i18n } = useTranslation();
  const { resolvedLanguage } = i18n;
  const language = capitalizeFirstChar(resolvedLanguage);

  let tempStoppageType = stoppageType;
  stoppageType = groupCode;
  groupCode = tempStoppageType;

  const {
    data: stoppageCodesData,
    error,
    isFetching,
    isLoading,
    refetch,
  } = useFetchStoppageCodesQuery(
    {
      line,
      machine,
      groupCode,
      groupType,
      stoppageType,
      showAutomaticStoppagesOnly,
      showInactiveCodes,
      language,
    },
    {
      skip,
    }
  );

  const { data: stoppageCodeHierarchy = {}, refetchHierarchy } =
    useFetchStoppageCodeHierarchyQuery({ language });

  const stoppageTypes = useMemo(
    () => Object.keys(stoppageCodeHierarchy),
    [stoppageCodeHierarchy]
  );

  const stoppageGroups = useMemo(() => {
    return stoppageTypes.reduce((acc, curr) => {
      const values = stoppageCodeHierarchy[curr];
      const names = values.map((value) => value.stoppageType);
      acc[curr] = names;

      return acc;
    }, {});
  }, [stoppageCodeHierarchy, stoppageTypes]);

  const stoppageSubgroups = useMemo(() => {
    return Object.entries(stoppageGroups).reduce((acc, curr) => {
      const [type, groups] = curr;
      const subgroups = groups.reduce((acc, curr) => {
        const array = stoppageCodeHierarchy[type];
        const found = array.find(({ stoppageType }) => stoppageType === curr);

        acc[curr] = found?.groupCodes;

        return acc;
      }, {});

      acc[type] = subgroups;

      return acc;
    }, {});
  }, [stoppageCodeHierarchy, stoppageGroups]);

  const stoppageCodes = useMemo(() => {
    if (isLoading) return [];

    return stoppageCodesData?.map?.((data) => {
      const keys = [
        "stoppageName",
        "stoppageType",
        "groupType",
        "groupCode",
        "explanation",
      ];
      const entries = Object.entries(data)
        ?.map?.(([key, val]) => {
          const prefix = keys.find((el) => key.startsWith(el));

          if (!prefix) return [key, val];
          if (key === `${prefix}${language}`) return [prefix, val];

          return false;
        })
        .filter(Boolean);

      return Object.fromEntries(entries);
    });
  }, [isLoading, language, stoppageCodesData]);

  useEffect(() => {
    refetch?.();
    refetchHierarchy?.();
  }, [language]);

  return {
    stoppageCodes,
    stoppageCodeHierarchy,
    stoppageTypes,
    stoppageGroups,
    stoppageSubgroups,
    error,
    isLoading,
    isFetching,
    refetch,
  };
};

export const useLanguageSuffix = () => {
  const { i18n } = useTranslation();
  const { resolvedLanguage } = i18n;
  const language = capitalizeFirstChar(resolvedLanguage);

  const suffixize = useCallback(
    (object) => {
      const entries = Object.entries(object)?.map?.(([key, val]) => {
        return [`${key}${language}`, val];
      });

      return Object.fromEntries(entries);
    },
    [language]
  );

  const desuffixize = useCallback(
    (object, keys = []) => {
      const entries = Object.entries(object)?.map?.(([key, val]) => {
        const found = keys.find((k) => key.startsWith(k));

        if (found) {
          if (key === `${found}${language}`) return [found, val];
          else return [];
        }

        return [key, val];
      });

      return Object.fromEntries(entries);
    },
    [language]
  );

  return { suffixize, desuffixize };
};
