import { forwardRef, useState } from "react";
import TextField from "@mui/material/TextField";
import styled from "@emotion/styled";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import _Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Input from "@mui/material/Input";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import isPlainObject from "lodash.isplainobject";
import { nanoid } from "nanoid";

export const TextInput = styled(TextField)(({ theme }) => ({
  "& label.Mui-focused": {
    color: theme.palette.primary,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: theme.palette.secondary,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.primary,
    },
    "&:hover fieldset": {
      borderColor: theme.palette.secondary,
    },
  },
}));

export const Select = forwardRef(
  (
    {
      label = "",
      options = [],
      onSelect,
      value = options[0]?.value,
      sx,
      ...rest
    },
    ref
  ) => {
    const handleChange = (event) => {
      onSelect(event.target.value);
    };

    return (
      <FormControl sx={sx}>
        {label && <InputLabel id={`${label}-select`}>{label}</InputLabel>}
        <_Select
          labelId={`${label}-select`}
          value={value}
          label={label}
          onChange={handleChange}
          sx={{ padding: "0.2rem" }}
          ref={ref}
          {...rest}
        >
          {options.map((option) => {
            const value = isPlainObject(option) ? option.value : option;
            const label = isPlainObject(option) ? option.label || option.value : option;
            const color = isPlainObject(option) ? option.viewColor : "initial";

            return (
              <MenuItem key={nanoid()} value={value} sx={{ color, maxWidth: "30rem" }}>
                {label}
              </MenuItem>
            );
          })}
        </_Select>
      </FormControl>
    );
  }
);

export const NumberInput = forwardRef(
  (
    {
      onChange = () => {},
      value = "",
      min = 1,
      max = 99999,
      step = 1,
      size = "large",
      ...rest
    },
    ref
  ) => {
    return (
      <Input
        value={value}
        size={size}
        inputProps={{
          step,
          min,
          max,
          type: "number",
        }}
        onChange={(event) => onChange(event.target.value)}
        ref={ref}
        {...rest}
      />
    );
  }
);

export const TimeInput = forwardRef((props, ref) => {
  return (
    <TimePicker
      ampm={false}
      renderInput={(params) => <TextField {...params} />}
      inputRef={ref}
      {...props}
    />
  );
});

export const WeekPickerInput = forwardRef(
  ({ value = moment(), onChange, ...rest }, ref) => {
    const [isPickerOpen, setIsPickerOpen] = useState(false);
    const startOfWeek = moment(value).startOf("week");
    const endOfWeek = moment(value).endOf("week");
    // const maxDate = moment().add(7, "d");
    const minDate = moment().startOf("year").subtract(2, "y");

    const renderWeekPickerDay = (
      currentDate,
      selectedDates,
      pickersDayProps
    ) => {
      if (!value) return <PickersDay {...pickersDayProps} />;

      const CustomPickersDay = styled(PickersDay, {
        shouldForwardProp: (prop) => prop !== "dayIsBetween",
      })(({ theme, dayIsBetween }) => {
        return {
          ...(dayIsBetween && {
            borderRadius: 0,
            backgroundColor: theme.palette.primary.main,
            color: "white",
            "&:hover, &:focus": {
              outline: "none",
              backgroundColor: theme.palette.primary.main,
            },
          }),
        };
      });

      const dayIsBetween = currentDate.isBetween(
        startOfWeek,
        endOfWeek,
        null,
        "[]"
      );

      return (
        <CustomPickersDay
          {...pickersDayProps}
          disableMargin
          dayIsBetween={dayIsBetween}
        />
      );
    };

    return (
      <DatePicker
        open={isPickerOpen}
        ref={ref}
        displayStaticWrapperAs="mobile"
        value={value}
        onChange={onChange}
        onClose={() => setIsPickerOpen(false)}
        renderDay={renderWeekPickerDay}
        renderInput={(params) => {
          params.inputProps.readOnly = true;
          params.inputProps.value = `${startOfWeek.format(
            "DD.MM.YYYY"
          )} - ${endOfWeek.format("DD.MM.YYYY")}`;

          return (
            <TextInput
              sx={{ cursor: "pointer" }}
              onClick={() => setIsPickerOpen(true)}
              {...params}
            />
          );
        }}
        minDate={minDate}
        showToolbar={false}
        closeOnSelect
        disableMaskedInput
        showDaysOutsideCurrentMonth
        {...rest}
      />
    );
  }
);
