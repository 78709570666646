import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ButtonGroup from "@mui/material/ButtonGroup";

import { FormWrapper } from ".";
import { Select, Button, TextInput } from "..";

export default ({
  TYPES,
  GROUPS,
  SUBGROUPS,
  selected = {},
  onSubmit = () => {},
  onCancel = () => {},
}) => {
  const { t } = useTranslation();
  const { control, handleSubmit, watch, reset } = useForm({
    mode: "onSubmit",
    defaultValues: {
      type: selected?.groupType ?? "",
      group: selected?.groupCode ?? "",
      subgroup: selected?.stoppageType ?? "",
      reason: selected?.stoppageName ?? "",
      },
  });
  const watched = watch();

  const handleFormSubmit = (data, event) => {
    event.preventDefault();
    onSubmit(data);
    handleReset();
  };

  const handleReset = () => {
    reset({
      type: "",
      group: "",
      subgroup: "",
      reason: "",
      });
  };

  return (
    <FormWrapper onSubmit={handleSubmit(handleFormSubmit)}>
      <Box>
        <Typography color="text.secondary" component="span" variant="h6">
          {t("Type")}
        </Typography>
        <Controller
          name="type"
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState }) => (
            <Select
              sx={{ minWidth: "5rem" }}
              options={TYPES}
              variant="standard"
              error={!field.value?.length}
              {...field}
            />
          )}
        />
      </Box>
      <Box>
        <Typography color="text.secondary" component="span" variant="h6">
          {t("Group")}
        </Typography>
        <Controller
          name="group"
          control={control}
          render={({ field }) => (
            <Select
              sx={{ minWidth: "5rem" }}
              options={GROUPS?.[watched.type]}
              variant="standard"
              disabled={!Boolean(watched.type)}
              {...field}
            />
          )}
        />
      </Box>
      <Box>
        <Typography color="text.secondary" component="span" variant="h6">
          {t("Subgroup")}
        </Typography>
        <Controller
          name="subgroup"
          control={control}
          render={({ field }) => (
            <Select
              sx={{ minWidth: "5rem" }}
              options={SUBGROUPS?.[watched.type]?.[watched.group]}
              variant="standard"
              disabled={!Boolean(watched.group)}
              {...field}
            />
          )}
        />
      </Box>
      <Box>
        <Typography color="text.secondary" component="span" variant="h6">
          {t("Reason")}
        </Typography>
        <Controller
          name="reason"
          control={control}
          render={({ field }) => (
            <TextInput
              disabled={!Boolean(watched.subgroup)}
              sx={{ minWidth: "25rem" }}
              {...field}
            />
          )}
        />
      </Box>
      <ButtonGroup
        variant="outlined"
        component="span"
        size="large"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
        }}
      >
        <Button onClick={onCancel}>{t("Cancel")}</Button>
        <Button onClick={handleReset}>{t("Reset")}</Button>
        <Button type="submit" color="success" variant="contained">
          {t("Edit")}
        </Button>
      </ButtonGroup>
    </FormWrapper>
  );
};
