import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

const Title = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  padding: theme.spacing(1),
  fontSize: "2rem",
  [theme.breakpoints.down("md")]: {
    fontSize: "1rem",
  },
}));

export const Header = ({ title }) => {
  const { t } = useTranslation();

  return (
    <AppBar position="static" sx={{ width: "100%" }}>
      <Title variant="h6" noWrap component="div">
        {t(title)}
      </Title>
    </AppBar>
  );
};
