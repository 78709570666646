// import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ButtonGroup from "@mui/material/ButtonGroup";

import { FormWrapper } from ".";
import { TextInput, Select, Button } from "..";

export default ({
  TYPES,
  GROUPS,
  SUBGROUPS,
  line,
  machine,
  onSubmit = () => {},
  onCancel = () => {},
}) => {
  const { t } = useTranslation();
  const { control, handleSubmit, watch, reset } = useForm({
    mode: "onSubmit",
    defaultValues: {
      groupType: "",
      stoppageType: "",
      groupCode: "",
      explanation: "",
    },
  });
  const watched = watch();

  const handleFormSubmit = (data, event) => {
    event.preventDefault();
    onSubmit(data);
    handleReset();
  };

  const handleReset = () => {
    reset({
      type: "",
      group: "",
      subgroup: "",
      reason: "",
    });
  };

  return (
    <FormWrapper onSubmit={handleSubmit(handleFormSubmit)}>
      <Box>
        <Typography color="text.secondary" component="span" variant="h6">
          {t("Type")}
        </Typography>
        <Controller
          name="groupType"
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState }) => (
            <Select
              sx={{ minWidth: "5rem" }}
              options={TYPES}
              variant="standard"
              error={!field.value?.length}
              {...field}
            />
          )}
        />
      </Box>
      <Box>
        <Typography color="text.secondary" component="span" variant="h6">
          {t("Group")}
        </Typography>
        <Controller
          name="stoppageType"
          control={control}
          render={({ field }) => (
            <Select
              sx={{ minWidth: "5rem" }}
              options={GROUPS?.[watched.groupType]}
              variant="standard"
              disabled={!Boolean(watched.groupType)}
              {...field}
            />
          )}
        />
      </Box>
      <Box>
        <Typography color="text.secondary" component="span" variant="h6">
          {t("Subgroup")}
        </Typography>
        <Controller
          name="groupCode"
          control={control}
          render={({ field }) => (
            <Select
              sx={{ minWidth: "5rem" }}
              options={SUBGROUPS?.[watched.groupType]?.[watched.stoppageType]}
              variant="standard"
              disabled={!Boolean(watched.stoppageType)}
              {...field}
            />
          )}
        />
      </Box>
      <Box>
        <Typography color="text.secondary" component="span" variant="h6">
          {t("Reason")}
        </Typography>
        <Controller
          name="explanation"
          control={control}
          render={({ field }) => (
            <TextInput disabled={!Boolean(watched.groupCode)} {...field}
             onChange={(e) => {
              const value = e.target.value;
              const isValid =
                new RegExp("^[a-zA-Z0-9 ]+$", "i").test(value) &&
                value.length < 100;

              if (isValid) field.onChange(value);
            }} />
          )}
        />
      </Box>
      <ButtonGroup
        variant="outlined"
        component="span"
        size="large"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
        }}
      >
        <Button onClick={onCancel}>{t("Cancel")}</Button>
        <Button onClick={handleReset}>{t("Reset")}</Button>
        <Button type="submit" color="success" variant="contained">
          {t("Add")}
        </Button>
      </ButtonGroup>
    </FormWrapper>
  );
};
