import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import _Button from '@mui/material/Button';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Input from '@mui/material/Input';

import signInLogo from '../assets/ms-symbollockup_signin_dark_short.svg';
import { Button } from '../components';

const Wrapper = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	height: '100vh',
	width: '100vw',
	backgroundColor: 'rgba(0,0,0,.8)',
}));

const SignInButton = styled(_Button)(({ theme }) => ({
	height: '4rem',
	width: '20rem',
	backgroundImage: `url(${signInLogo})`,
	backgroundRepeat: 'no-repeat',
	backgroundSize: 'contain',
	backgroundPosition: 'bottom center, 50%, 50%',
}));

export const LoginBox = ({ onSignIn }) => {
	const USER_ROLES = useSelector((state) => state.user.USER_ROLES);
	const [userRole, setUserRole] = useState(USER_ROLES[0]);

	const onLogin = () => {
		onSignIn(userRole);
	};

	return (
		<Wrapper>
			<Box
				sx={{
					height: 300,
					width: 600,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
					backgroundColor: 'primary.main',
					borderRadius: '3rem',
					'& > *': {
						margin: '1rem 0',
					},
				}}
			>
				<Typography variant='h3' gutterBottom sx={{ color: 'primary.accent' }}>
					TWINMASTER
				</Typography>
				<SignInButton onClick={onLogin} />
				{process.env.NODE_ENV !== 'production' && (
					<select onChange={(ev) => setUserRole(ev.target.value)}>
						{USER_ROLES.map((role) => (
							<option key={role} value={role}>
								{role}
							</option>
						))}
					</select>
				)}
			</Box>
			<Box
				sx={{
					color: 'white',
					position: 'fixed',
					bottom: 10,
					'& > span': {
						cursor: 'pointer',

						'&:hover': {
							color: 'primary.main',
						},
					},
				}}
			>
				<Typography variant='span'>Support</Typography>
				{' | '}
				<Typography variant='span'>Documentation</Typography>
			</Box>
		</Wrapper>
	);
};

export const WrappedPinBox = ({ onPin }) => {
	const [pin, setPin] = useState('');
	const maxLength = 4;

	return (
		<Wrapper>
			<PinBox
				maxLength={maxLength}
				pin={pin}
				setPin={setPin}
				onSave={() => onPin(pin)}
			/>
		</Wrapper>
	);
};

export const PinBox = ({ setPin, pin, maxLength = 4, onSave }) => {
	const { t } = useTranslation();

	const onClick = (event) => {
		event.preventDefault();
		event.stopPropagation();

		onSave(event.target.value);
	};

	return (
		<Box
			sx={{
				height: 300,
				width: 600,
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				backgroundColor: 'primary.main',
				borderRadius: '3rem',
				margin: 'auto',
				'& > *': {
					margin: '1rem 0!important',
				},

				'& input': {
					fontSize: '2rem',
					color: 'primary.accent',
					textAlign: 'center',
				},
			}}
		>
			<Typography variant='h3' gutterBottom sx={{ color: 'primary.accent' }}>
				PIN
			</Typography>
			<Input
				autoFocus
				value={pin}
				onChange={(event) => setPin(event.target.value)}
				color='secondary'
				inputProps={{
					maxLength,
					pattern: '[a-z0-9]{4}',
				}}
			/>
			<Button
				disabled={pin?.length < maxLength}
				color='success'
				variant='contained'
				onClick={onClick}
			>
				{t('Save')}
			</Button>
		</Box>
	);
};
