import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export { default as ProductionStatus } from './production-status';
export { default as StartEndProduction } from './start_end-production';
export { default as OperatorCheckin } from './operator-checkin';
export { default as Stoppages } from './stoppages';
export { default as DefineLineStoppage } from './define-line-stoppage';
export { default as ShiftDefinition } from './shift-definition';
export { default as StoppageCodeManagement } from './stoppage-code-management';
export { default as UserManagement } from './user-management';
export { default as ShiftPlanning } from './shift-planning';
export { default as WatchPerformance } from './watch-performance';

export const Wrapper = styled(Box)(({ theme, flex, center, direction }) => ({
	width: '100%',
	display: flex ? 'flex' : 'grid',
	alignItems: center ? 'center' : 'inherit',
	justifyContent: center ? 'center' : 'inherit',
	flexDirection: direction ?? 'inherit',
	gridTemplateColumns: '1fr',
	gap: '.5rem',
	margin: '1rem auto 4rem auto',
	padding: theme.spacing(3),
	[theme.breakpoints.up('lg')]: {
		display: flex ? 'flex' : 'grid',
		gridTemplateColumns: '1fr 1fr',
		padding: theme.spacing(5),
		gap: '1rem',
	},
	[theme.breakpoints.down('md')]: {
		margin: 0,
	},
	'& > .MuiCard-root': {
		justifySelf: 'center',
	},
}));
