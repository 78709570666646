import styled from "@emotion/styled";

export const FormWrapper = styled.form(({ theme }) => ({
  "& > div": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0.5rem 0",
    padding: "0.5rem",
  },
}));

export { default as AddMachineStoppage } from "./add-machine-stoppage";
export { default as EditMachineStoppage } from "./edit-machine-stoppage";
export { default as AddLineStoppage } from "./add-line-stoppage";
export { default as EditLineStoppage } from "./edit-line-stoppage";
export { default as AddShift } from "./add-shift";
export { default as EditShift } from "./edit-shift";
export { default as AddReason } from "./add-reason";
export { default as EditReason } from "./edit-reason";
export { default as CloneReason } from "./clone-reason";
export { default as EditUser } from "./edit-user";
export { default as ConfirmStoppage } from "./confirm-stoppage";
