import { useState, useContext, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useFuzzy } from "react-use-fuzzy";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import ButtonGroup from "@mui/material/ButtonGroup";
import pick from "lodash.pick";

import { Wrapper } from ".";
import {
  Footer,
  Button,
  Toolbar,
  Toast,
  PinBox,
  Dialog,
  ListItem,
} from "../components/";
import {
  AbilityContext,
  useFetchProductionPlanQuery,
  useStartProductionMutation,
  useEndProductionMutation,
  usePlant,
  useValidateUserActionMutation,
  useFetchCheckinStatusQuery,
  setUserStation,
} from "../state/";
import { formatDate } from "../util";

export default () => {
  const { t } = useTranslation(["translation", "actions"]);
  const dispatch = useDispatch();
  const ability = useContext(AbilityContext);
  const { allLines: LINES } = usePlant();
  const userStation = useSelector((state) => state.user.station);
  const userToken = useSelector((state) => state.user.token);
  const [line, setLine] = useState(userStation?.line || "");
  const [selected, setSelected] = useState(null);
  const [pin, setPin] = useState("");
  const [needsPin, setNeedsPin] = useState(true);
  const [isStartOpen, setIsStartOpen] = useState(false);
  const [isEndOpen, setIsEndOpen] = useState(false);
  const isOperator = !ability.can("edit", "Operator");
  const {
    data,
    error: fetchProductionPlanError,
    isLoading,
    refetch,
  } = useFetchProductionPlanQuery(
    {
      line,
      startDate: formatDate(moment().add(-2, "days")),
      endDate: formatDate(moment().add(2, "days")),
    },
    {
      skip: !Boolean(line),
    }
  );
  const { data: checkinStatus, error: fetchCheckinStatusError } =
    useFetchCheckinStatusQuery({
      skip: !Boolean(userToken),
    });

  const [onValidateUserAction, { isLoading: isValidating }] =
    useValidateUserActionMutation();
  const [onStartProduction, { isLoading: isStarting }] =
    useStartProductionMutation();
  const [onEndProduction, { isLoading: isEnding }] = useEndProductionMutation();
  const [hasError, setHasError] = useState(
    Boolean(fetchProductionPlanError) &&
      Object.keys(fetchProductionPlanError).length
  );
  const {
    result: rows = [],
    keyword,
    search,
  } = useFuzzy(data, {
    keys: [
      "jobNumber",
      "planNumber",
      "startDate",
      "explanation",
      "amount",
      "stockNumber",
      "capacity",
      "versionNo",
    ],
    isCaseSensitive: true,
    minMatchCharLength: 2,
    shouldSort: true,
    findAllMatches: true,
  });
  const columns = [
    { field: "jobNumber", hide: true },
    { field: "planNumber", flex: false },
    { field: "startDate" },
    { field: "stockNo", keyName: "stockNumber", flex: false },
    { field: "Explanation", keyName: "explanation" },
    { field: "Amount", keyName: "amount", flex: false },
    { field: "Capacity", keyName: "capacity", flex: false },
    { field: "Version", keyName: "versionNo", flex: false },
  ].map(({ field, keyName, ...rest }) => ({
    field: keyName || field,
    headerName: t(field),
    flex: true,
    align: "center",
    headerAlign: "center",
    ...rest,
  }));

  useEffect(() => {
    if (userToken) {
      dispatch(setUserStation(checkinStatus));
    }
  }, [userToken]);

  const onRowClick = (params, event) => {
    const { row } = params;
    const { target } = event;

    if (row.planNumber !== selected?.planNumber) {
      setSelected(row);
      setTimeout(() => {
        target.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }, 30);
    }
  };

  const onValidate = async () => {
    try {
      const result = await onValidateUserAction({
        code: pin,
      }).unwrap();
      setNeedsPin(false);
    } catch (validateError) {
      setHasError(validateError?.data?.message);
    } finally {
      setPin("");
    }
  };

  const onStart = async () => {
    const { planNumber } = selected;

    try {
      const result = await onStartProduction({
        startDate: formatDate(moment()),
        planNumber,
        line: userStation?.line,
        machine: userStation?.machine,
      }).unwrap();

      refetch();
    } catch (startProductionError) {
      setHasError(startProductionError?.data?.message);
    } finally {
      setIsStartOpen(false);
      setSelected(null);
    }
  };

  const onEnd = async () => {
    try {
      const result = await onEndProduction().unwrap();

      refetch();
      setNeedsPin(false);
    } catch (endProductionError) {
      setHasError(endProductionError?.data?.message);
    } finally {
      setIsEndOpen(false);
      setSelected(null);
    }
  };

  const onCloseStart = () => {
    setIsStartOpen(false);
    setNeedsPin(true);
    setPin("");
  };

  const onCloseEnd = () => {
    setIsEndOpen(false);
    setNeedsPin(true);
    setPin("");
  };

  return (
    <Wrapper component="section" flex>
      <div style={{ flexGrow: 1 }}>
        <DataGrid
          rows={
            Boolean(fetchProductionPlanError) || isLoading
              ? []
              : rows?.[0]?.item
              ? rows.map(({ item }) => item)
              : rows
          }
            getRowClassName={(params) => {
              return params.row.productionStarted
                ? `MuiDataGrid-row Mui-success`
                : `MuiDataGrid-row`;
            }}
          columns={columns}
          getRowId={(row) => row.planNumber}
          autoHeight
          pageSize={25}
          components={{ Toolbar }}
          componentsProps={{
            toolbar: {
              search,
              keyword,
              LINES,
              ...(ability.can("edit", "Operator") ? { line, setLine } : {}),
            },
          }}
          sx={{
              "& .Mui-success": {
                bgcolor: "lightgreen",
              },
            "& .MuiDataGrid-cell:focus": {
              outline: "none",
            },
            "& .MuiDataGrid-columnHeader:focus": {
              outline: "none",
            },
          }}
          onRowClick={onRowClick}
        />
      </div>
      <Footer>
        <Button
          onClick={() => setIsStartOpen(true)}
          disabled={
            !Boolean(line) ||
            !Boolean(selected) ||
            Boolean(selected?.productionStarted)
          }
        >
          {t("Start Production", { ns: "actions" })}
        </Button>
        <Button
          onClick={() => setIsEndOpen(true)}
          disabled={
            !Boolean(line) ||
            !Boolean(selected) ||
            !Boolean(selected?.productionStarted)
          }
        >
          {t("End Production", { ns: "actions" })}
        </Button>
      </Footer>
      <Dialog
        isOpen={isStartOpen}
        handleClose={onCloseStart}
        title={t("Are you sure?")}
        actions={
          <ButtonGroup
            variant="outlined"
            component="span"
            size="large"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Button onClick={onCloseStart}>{t("No")}</Button>
            <Button onClick={onStart} color="success" variant="contained">
              {t("Yes")}
            </Button>
          </ButtonGroup>
        }
      >
        {isOperator && needsPin ? (
          <Box my={2}>
            <PinBox setPin={setPin} pin={pin} onSave={onValidate} />
          </Box>
        ) : (
          <ListItem
            heading={t("Starting")}
            entity={pick(selected, ["planNumber", "startDate", "explanation"])}
          />
        )}
      </Dialog>
      <Dialog
        isOpen={isEndOpen}
        handleClose={onCloseEnd}
        title={t("Are you sure?")}
        actions={
          <ButtonGroup
            variant="outlined"
            component="span"
            size="large"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Button onClick={onCloseEnd}>{t("No")}</Button>
            <Button onClick={onEnd} color="success" variant="contained">
              {t("Yes")}
            </Button>
          </ButtonGroup>
        }
      >
        {isOperator && needsPin ? (
          <Box my={2}>
            <PinBox setPin={setPin} pin={pin} onSave={onValidate} />
          </Box>
        ) : (
          <ListItem
            heading={t("Ending")}
            entity={pick(selected, ["planNumber", "startDate", "explanation"])}
          />
        )}
      </Dialog>
      <Toast
        isOpen={Boolean(hasError)}
        onClose={() => setHasError(false)}
        severity="error"
      >
        {t(fetchProductionPlanError?.data?.message || hasError)}
      </Toast>
    </Wrapper>
  );
};
