import _Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import DialogContentText from "@mui/material/DialogContentText";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";

export const Dialog = ({
  isOpen,
  handleClose,
  title,
  actions,
  children,
  description,
  maxWidth = "md",
  ...rest
}) => {
  const theme = useTheme();
  const isFullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box>
      <_Dialog
        fullScreen={isFullScreen}
        open={isOpen}
        onClose={handleClose}
        maxWidth={maxWidth}
        fullWidth
        {...rest}
      >
        {title && (
          <DialogTitle
            sx={{
              backgroundColor: "primary.main",
              color: "secondary.main",
            }}
          >
            {title}
          </DialogTitle>
        )}
        {description && <DialogContentText>{description}</DialogContentText>}
        <DialogContent>{children}</DialogContent>
        {actions && <DialogActions>{actions}</DialogActions>}
      </_Dialog>
    </Box>
  );
};
