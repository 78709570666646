import { useMemo, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";

import { Footer, Button, Card, Select, Toast } from "../components/";
import { Wrapper } from ".";
import {
  useFetchProductionStatusQuery,
  useFetchProductionStatusProductsQuery,
  usePlant,
} from "../state/";
import { formatDate } from "../util";

export default () => {
  const { t } = useTranslation(["translation", "actions"]);
  const navigate = useNavigate();
  const userStation = useSelector((state) => state.user.station);
  const [line, setLine] = useState(userStation?.line || "");
  const [machine, setMachine] = useState(userStation?.machine || "");
  const [product, setProduct] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);

  const { data, error, isLoading } = useFetchProductionStatusQuery(
    {
      line,
      machine,
      jobNumber: product,
    },
    {
      pollingInterval: 5000,
    },
    {
      skip: !Boolean(product),
    }
  );
  const {
    data: productionPlan = [],
    error: fetchProductionPlanError,
    refetch: refetchProductionQuery,
  } = useFetchProductionStatusProductsQuery(
    {
      line,
      machine,
      startDate: formatDate(moment()),
      endDate: formatDate(moment().add(1, "days")),
    },
    {
      skip: !Boolean(line) || !Boolean(machine),
    }
  );

  const { allLines: LINES, machinesByLine } = usePlant();
  const MACHINES = useMemo(
    () => machinesByLine?.[line]?.map?.(({ machine }) => machine),
    [line, machinesByLine]
  );
  const PRODUCTS = useMemo(
    () =>
      productionPlan.map?.(({ planNumber, jobNumber, explanation }) => ({
        value: jobNumber,
        label: `${planNumber}-${explanation}`,
      })),
    [productionPlan]
  );

  useEffect(() => {
    if (error) setErrorMessage(error);
  }, [error]);

  const result = useMemo(() => {
    if (!data) return [];

    return Object.entries(data).map(([title, values]) => {
      const rows = Object.entries(values).map(([name, value]) => ({
        name,
        value,
      }));

      return { title, rows };
    });
  }, [data]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: ".5rem",
          marginTop: "1rem",
        }}
      >
        <Select
          label={t("Line")}
          value={line}
          options={LINES}
          onSelect={setLine}
          sx={{ minWidth: "5rem" }}
          variant="standard"
        />
        <Select
          label={t("Machine")}
          value={machine}
          options={MACHINES}
          onSelect={setMachine}
          sx={{ minWidth: "7rem" }}
          variant="standard"
        />
        <Select
          label={t("Product")}
          value={product}
          options={PRODUCTS}
          onSelect={setProduct}
          sx={{ minWidth: "7rem" }}
          variant="standard"
        />
      </Box>
      <Wrapper component="section">
        {result.map(({ title, rows }) => {
          return (
            <Card key={title} square title={t(title)}>
              {rows.map((row) => {
                const { name, value } = row;

                return (
                  <Box
                    key={name}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      color="text.secondary"
                      component="span"
                      variant="h6"
                    >
                      {t(name)}
                    </Typography>
                    <Typography
                      color="text.secondary"
                      component="span"
                      variant="body1"
                      ml="auto"
                    >
                      {value}
                    </Typography>
                    <br />
                  </Box>
                );
              })}
            </Card>
          );
        })}
        <Footer>
          <Button onClick={() => navigate("/start-end-production")}>
            {t("Start/End Job", { ns: "actions" })}
          </Button>
          <Button onClick={() => navigate("/stoppages")}>
            {t("Stoppages")}
          </Button>
        </Footer>
        <Toast
          isOpen={Boolean(errorMessage)}
          onClose={() => setErrorMessage(null)}
          severity="error"
        >
          {errorMessage}
        </Toast>
      </Wrapper>
    </>
  );
};
