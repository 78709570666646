import { useEffect, useCallback } from 'react';
export default () => {
	const escFunction = useCallback((event) => {
		if (event.key === 'Escape') {
			alert('HEEYY');
		}
	}, []);

	useEffect(() => {
		document.addEventListener('keydown', escFunction, false);

		return () => {
			document.removeEventListener('keydown', escFunction, false);
		};
	}, [escFunction]);

	return (
		<>
			<iframe
				width='100%'
				height='1000px'
				src={`https://grafana.twinmaster.io/d/f84ad013-ba29-4c0c-be02-da6fa1cc69f9/test?orgId=1&refresh=5s&theme=light&kiosk=tv`}
			/>
		</>
	);
};
