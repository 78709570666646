import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ButtonGroup from "@mui/material/ButtonGroup";
import moment from "moment";

import { FormWrapper } from ".";
import { TextInput, TimeInput, Button } from "..";

export default ({ shift, onSubmit = () => {}, onCancel = () => {} }) => {
  const { t } = useTranslation();
  const defaultValues = Boolean(shift?.startTime)
    ? {
        startTime: moment(shift.startTime, "HH:mm"),
        endTime: moment(shift.endTime, "HH:mm"),
        shiftName: shift.shiftName,
      }
    : {
        startTime: moment(),
        endTime: moment(),
        shiftName: "",
      };
  const { control, handleSubmit, reset } = useForm({
    mode: "onSubmit",
    defaultValues,
  });

  const handleFormSubmit = (data, event) => {
    event.preventDefault();
    onSubmit(data);
    handleReset();
  };

  const handleReset = () => {
    reset({ shiftName: "" });
  };

  return (
    <FormWrapper onSubmit={handleSubmit(handleFormSubmit)}>
      <Box>
        <Typography color="text.secondary" component="span" variant="h6">
          {t("startTime")}
        </Typography>
        <Controller
          name="startTime"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TimeInput error={!field.value?.length} {...field} />
          )}
        />
      </Box>
      <Box>
        <Typography color="text.secondary" component="span" variant="h6">
          {t("endTime")}
        </Typography>
        <Controller
          name="endTime"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TimeInput error={!field.value?.length} {...field} />
          )}
        />
      </Box>

      <Box>
        <Typography color="text.secondary" component="span" variant="h6">
          {t("shiftName")}
        </Typography>
        <Controller
          name="shiftName"
          control={control}
          rules={{ required: true }}
          render={() => (
            <Controller
              name="shiftName"
              control={control}
              render={({ field }) => <TextInput {...field} />}
            />
          )}
        />
      </Box>
      <ButtonGroup
        variant="outlined"
        component="span"
        size="large"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
        }}
      >
        <Button onClick={onCancel}>{t("Cancel")}</Button>
        <Button onClick={handleReset}>{t("Reset")}</Button>
        <Button type="submit" color="success" variant="contained">
          {t("Edit")}
        </Button>
      </ButtonGroup>
    </FormWrapper>
  );
};
