import { AbilityBuilder, Ability } from "@casl/ability";

const { can, cannot, build } = new AbilityBuilder(Ability);

export const defineAbilityFor = role => {
  switch (role) {
    case "key-user": { // admin
      can("manage", "all");
      cannot("view", "OperatorOnly")
      break;
    }
    case "non-operator": { // manager
      can("view", "Manager");
      can("manage", "Operator");
      cannot("view", "Admin");
      break;
    }
    default: { // operator
      can("view", "OperatorOnly")
      can("view", "Operator");
      cannot("view", "Manager");
      cannot("edit", "Operator");
    }
  }

  return build();
};
